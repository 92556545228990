<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-md-3">
        <div class="app">
          <a target="_blank" href="https://getquipu.com/es/entrar">
            <img src="" alt="">
            <span>Quipu</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "tables",
  components: {

  },
};
</script>
