<template>
    <div class="py-4 container-fluid">
      <div v-if="!loading && error" class="alert">
        <div class="alert alert-danger">
          Parece que hay algún error
        </div>
      </div>
      <div v-if="!loading && !error" class="row">
        <div class="col-md-8">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <!-- info general -->
                <div class="col-md-6">
                  Identificador: <h6>  #000{{ticket.id}}</h6>
                  Título:<h6> {{ticket.title}}</h6>
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-calendar-range-fill mr-1" viewBox="0 0 16 16">
                    <path d="M4 .5a.5.5 0 0 0-1 0V1H2a2 2 0 0 0-2 2v1h16V3a2 2 0 0 0-2-2h-1V.5a.5.5 0 0 0-1 0V1H4V.5zM16 7V5H0v5h5a1 1 0 1 1 0 2H0v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9h-6a1 1 0 1 1 0-2h6z"/>
                  </svg>
                  <h6 v-if="ticket.expiration_on !== null">
                    Caduca el {{ticket.expiration_on}}
                  </h6>
                  <div v-else><span>Sin fecha de expiración</span> <br></div>
                  <span>Creado el {{this.$store.state.dateFormat(ticket.created_at)}}</span> <br>
                  <span>Actualizado el {{this.$store.state.dateFormat(ticket.updated_at)}}</span> <br>
                  <hr>
                  <h6>Descripción</h6>
                  <p>
                    {{ticket.description}}
                  </p>

                </div>
                <!--  -->
                <div class="col-md-6">
                  <div v-if="ticket.company">
                    Empresa:
                    <avatarCompany 
                  v-if="ticket.company"
                  size="xl"
                  border-radius="lg"
                  alt="user1"
                  :company="ticket.company"
                  :circular="true"
                  />
                    
                    
                  </div>
                  <div v-else>
                    <h6>Asunto Sin empresa asociada</h6>
                  </div>
                  <span>Estado:</span>

                  <div class="" v-if="this.$store.state.auth.rol !== 'client'">
                    <button
                      class="btn btn-secondary dropdown-toggle m-2"
                      type="button"
                      id="dropdownMenuButton2"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                    <span v-if="ticket.status == 'done'"><i class="fa fa-circle me-1 m-1 success"></i>Resuelto</span>
                    <span v-else><i class="fa fa-circle me-1 m-1 pending"></i>Pendiente</span>
                    <Cargador v-if="loadingChanges" />
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton2">
                      <li><a @click="changeStatus('done')" class="dropdown-item" href="#">Resuelto</a></li>
                      <li><a @click="changeStatus('pending')" class="dropdown-item" href="#">Pendiente</a></li>
                      <!-- <li><a @click="changeStatus('error')" class="dropdown-item" href="#">Error</a></li> -->
                    </ul>
                  </div>

                  <div v-else>
                    <span v-if="ticket.status == 'done'"><i class="fa fa-circle me-1 m-1 success"></i>Resuelto</span>
                    <span v-else><i class="fa fa-circle me-1 m-1 pending"></i>Pendiente</span>

                  </div>
                  
                  
                  
                  

                  <div v-if="this.$store.state.auth.rol !== 'client'" class="mt-3">
                   Tiempo dedicado
                      <p>
                        <strong>Total:</strong>
                         {{ticket.total_time_human}}
                      </p>
                      <p v-if="ticket.today_timing !== null">
                        <!-- <strong>Última entrada de tiempo de Hoy:</strong> {{ticket.today_timing.minuts}} minutos -->
                      </p>
                      <p v-else>
                        Hoy no has añadido minutos
                      </p>
                      <Cargador v-if="this.loadingTiming"/>
                      <div v-else class="form-group">
                        <label class="" for="">Añadir minutos Hoy: </label>
                        <input v-on:keyup.enter="addTimming($event)" v-model="this.timingIn" placeholder="Num Minutos" class="form-control"  >
                      </div>
                  </div>



                </div>

              </div>




              <h6 class="mt-2">Accesos</h6>
              <div>
                <a href="#requestForms">
                  <button type="button" class="btn btn-info m-2" @click="showForms = !showForms" name="button">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-file-earmark-text-fill" viewBox="0 0 16 16">
                      <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM4.5 9a.5.5 0 0 1 0-1h7a.5.5 0 0 1 0 1h-7zM4 10.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm.5 2.5a.5.5 0 0 1 0-1h4a.5.5 0 0 1 0 1h-4z"/>
                    </svg>
                    Formularios del ticket


                  </button>
                </a>

                <a href="#documents">
                  <button type="button" class="btn btn-info m-2" name="button" @click="showDocuments = !showDocuments" >
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-folder-fill" viewBox="0 0 16 16">
                      <path d="M9.828 3h3.982a2 2 0 0 1 1.992 2.181l-.637 7A2 2 0 0 1 13.174 14H2.825a2 2 0 0 1-1.991-1.819l-.637-7a1.99 1.99 0 0 1 .342-1.31L.5 3a2 2 0 0 1 2-2h3.672a2 2 0 0 1 1.414.586l.828.828A2 2 0 0 0 9.828 3zm-8.322.12C1.72 3.042 1.95 3 2.19 3h5.396l-.707-.707A1 1 0 0 0 6.172 2H2.5a1 1 0 0 0-1 .981l.006.139z"/>
                    </svg>
                    Documentos del ticket
                  </button>
                </a>
                <router-link
                 v-if="ticket.chat_id"
                    :to="{
                      name: 'Chat',
                      params: {
                        id: ticket.chat_id
                      }
                    }">
                  <button type="button" class="btn btn-info m-2" name="button" @click="showDocuments = !showDocuments" >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chat-dots-fill" viewBox="0 0 16 16">
                      <path d="M16 8c0 3.866-3.582 7-8 7a9.06 9.06 0 0 1-2.347-.306c-.584.296-1.925.864-4.181 1.234-.2.032-.352-.176-.273-.362.354-.836.674-1.95.77-2.966C.744 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7zM5 8a1 1 0 1 0-2 0 1 1 0 0 0 2 0zm4 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0zm3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
                    </svg>
                    Chat
                  </button>
                </router-link>
              </div>

            </div>
          </div>
          <div v-if="showForms" class="row mt-4" id="requestForms">
            <div class="col-md-12">
              <div class="card">
                <div class="card-body">
                  <h6>Formularios</h6>
                  <RquestsForms
                  :filters="{
                    ticket_id: ticket.id,
                    from_id: null,
                    status: null
                    }" />
                </div>
              </div>
            </div>
          </div>
          <div v-if="showDocuments" class="row mt-4" id="documents">
            <div class="col-md-12">
              <div class="card">
                <div class="card-body">
                  <h6>Documentos</h6>
                  <tableDoc
                  :showFolders="false"
                  :filters="{
                    ticket_id: ticket.id,
                    department_id: null,
                    year:null,
                    showDepartments: false,
                    showFolders: false
                    }" />
                </div>
              </div>
            </div>
          </div>





        </div>
        <div class="col-md-4">
          <h6>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-calendar-range-fill ml-2" viewBox="0 0 16 16">
              <path d="M4 .5a.5.5 0 0 0-1 0V1H2a2 2 0 0 0-2 2v1h16V3a2 2 0 0 0-2-2h-1V.5a.5.5 0 0 0-1 0V1H4V.5zM16 7V5H0v5h5a1 1 0 1 1 0 2H0v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9h-6a1 1 0 1 1 0-2h6z"/>
            </svg>
            Futuras Reuniones Asignadas
            <button @click="requestMeet()" type="button" class="btn btn-secondary m-2" name="button">Solicitar Reunión</button>

          </h6>


          <miniMeet v-for="meet in this.ticket.next_meets" :key="meet.id" :meet="meet"></miniMeet>
          <router-link
          :to="{
            path:'/meets'
            }"

            class="text-body text-sm font-weight-bold mb-0 icon-move-right mt-auto"
          >
            Todas las reuniones
            <i
              class="fas fa-arrow-right text-sm ms-1"
              aria-hidden="true"
            ></i>
          </router-link>
        </div>
      </div>
      <div  class="row">
        <div class="col-md-8">
          <div class="card mt-4">
            <div class="card-body">
              <h6>Participantes</h6>
              <div class="contieneAsesoresScroll mt-4">
                <vsud-avatar
                  v-for="asesor in ticket.asesores"
                  :key="asesor.id"
                  size="xl"
                  border-radius="lg"
                  alt="user1"
                  :span="isAdmin(asesor.pivot)"
                  :user="asesor"
                  :circular="true"
                />
               


              </div>
              <!-- Info de cliente -->
              <div v-if="ticket.client && this.$store.state.auth.id !== ticket.client.id" class="col-md-12 mt-3">
                <h6>Cliente:</h6>
                <vsud-avatar
                  size="xl"
                  border-radius="lg"
                  alt="user1"
                  :span="isAdmin(ticket.client)"
                  :user="ticket.client"
                  :circular="true"
                />
              </div>
              <!-- Info de la empresa -->
            </div>

          </div>
        </div>
        <div v-if="this.$store.state.auth.rol !== 'client'" class="col-md-4">
          <div class="card">
            <div class="card-body">
              <div  class="mt-4">
                <div class="">
                  <h6>Notas privadas </h6>
                  <span>(Solo visible a Asesores)</span>
                  <div class="form-group mt-2">
                   <textarea class="form-control"  v-model="ticket.private_notes" id="exampleFormControlTextarea1" rows="8"></textarea>
                   <button @click="this.update()" type="button" class="btn btn-info mt-4" name="button">Guardar   <Cargador v-if="loadingChanges" /> </button>
                 </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div v-if="false" class="row">
        <div class="mt-4">
          <h4>Comentarios - Seguimiento</h4>
          <addComment :parent_comment="null" :comentarios="ticket.comments"/>
          <Comments v-for="comment in ticket.comments" :key="comment.id" :comentario="comment"/>
        </div>
      </div>

    </div>
</template>

<script>
import Comments from "@/views/components/Tickets/comments.vue";
import addComment from "@/views/components/Tickets/addComment.vue";
import VsudAvatar from "@/components/VsudAvatar.vue";
import Cargador from "@/components/Cargador.vue";
import RquestsForms from "@/views/components/Forms/table.vue";
import tableDoc from "./components/Documentacion/table";
import miniMeet from "@/views/components/Tickets/miniMeet";
import AvatarCompany from "@/components/VsudAvatarCompany.vue";
import axios  from 'axios';

export default {
  name: "TicketView",
  data() {
    return {
      ticket: {},
      loading: true,
      loadingChanges: false,
      error:false,
      showForms:false,
      showDocuments: false,
      timingIn: "",
      loadingTiming: false


    }
  },
  components: {
    Cargador,
    VsudAvatar,
    Comments,
    addComment,
    RquestsForms,
    tableDoc,
    miniMeet,
    AvatarCompany
  },
  created(){
    this.getData()
  },
  methods: {
    isAdmin(pivot) {
      if(pivot.admin)
        return "admin"
      return false;
    },
    getData() {
      var token = this.$store.state.token
      axios.defaults.headers.common['Authorization'] = 'Bearer '+token
      axios.get(this.$store.state.baseUrl+'/api/tickets/'+this.$route.params.id, null)
        .then(response => {
          if(response.data.rc == 1) {
            this.ticket = response.data.data
            this.loading = false
          } else {
            this.error = true
          }
        })
        .catch(() =>  {
            this.error = true
        })
        .finally(() =>  {
            this.loading = false
        })
    },
    requestMeet(){
      if(this.ticket.next_meets.length>=1) {
        if(!confirm('Ya tienes una reunión asignada. ¿estás seguro de solcitar otra?'))
          return true
      }
      this.$router.push({
        name: 'Solicitar Reunión',
        query: {
          ticket_id: this.ticket.id
          },
          props: true
        })


      // window.location.href = '/meets/request';
    },
    changeStatus(status) {
      this.loadingChanges = true;
      this.ticket.status = status;
      this.update();
    },
    update() {
      this.loadingChanges = true
      var token = this.$store.state.token
      axios.defaults.headers.common['Authorization'] = 'Bearer '+token
      var self = this
      axios.post(this.$store.state.baseUrl+'/api/tickets/'+this.$route.params.id+'/update', this.ticket)
        .then(response => {
          if(response.data.rc == 1) {
            self.ticket = response.data.data
          } else {
            this.error = true
          }
        })
        .catch(function () {
            self.error = true
        })
        .finally(
          this.loadingChanges = false
        )
    },
    addTimming(event) {
      this.loadingTiming = true
      var token = this.$store.state.token
      axios.defaults.headers.common['Authorization'] = 'Bearer '+token
      var self = this
      axios.post(this.$store.state.baseUrl+'/api/tickets/'+this.ticket.id+'/timing', {
        minuts: event.target.value
      })
        .then(response => {
          if(response.data.rc == 1) {
            this.ticket.total_time = response.data.data.total_time
            this.ticket.total_time_human = response.data.data.total_time_human
            this.ticket.today_timing = response.data.data.today_timing
            this.timingIn = null
            
          } else {
            this.error = true
          }
        })
        .catch(function () {
            self.error = true
        })
        .finally(
          this.loadingTiming = false
        )
    },
  }

};
</script>
