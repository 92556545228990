<template>
  <div class="py-4 container-fluid mt-3">
    <div class="row">
      <div class="col-12">
        <div class="card mb-4">
          <div class="card-header pb-0">
            <h5 class="font-weight-bolder">Próximas reuniones:</h5>
            <router-link
            v-if="this.$store.state.auth.rol == 'asesor'"
            :to="{
              name: 'Solicitar Reunión',

            }"
            >
            <div class="col-md-6">
              <div class="filter m-auto w-100">
                <button type="button" class="btn btn-secondary" name="button">Crear Reunión</button>
              </div>
            </div>
            </router-link>
            <!-- <div class="container-fluid">
              <div class="row d-flex justify-content-between">

                <div class="col-md-6 d-flex">
                  <div class="filter m-auto w-100">
                    <div class="input-group m-auto">
                      <span class="input-group-text text-body">
                        <i class="fas fa-search" aria-hidden="true"></i>
                      </span>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Buscar"
                      />
                    </div>
                  </div>
                </div>

                <div class="col-md-3 d-flex ">
                  <div class="m-1">
                    <small class="ml-5">Filtrar:</small> <br>
                    <div class="dropdown">
                      <button
                        class="btn btn-secondary dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton2"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Todos
                      </button>
                      <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton2">
                        <li><a class="dropdown-item" href="#">Todos</a></li>
                        <li><a class="dropdown-item" href="#">Pendientes</a></li>
                        <li><a class="dropdown-item" href="#">Resueltos</a></li>

                      </ul>
                    </div>
                  </div>

                  <div class="m-1">
                    <small class="ml-5">Ordenar:</small> <br>
                    <div class="dropdown">
                      <button
                        class="btn btn-secondary dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton2"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Todos
                      </button>
                      <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton2">
                        <li><a class="dropdown-item" href="#">Todos</a></li>
                        <li><a class="dropdown-item" href="#">Pendientes</a></li>
                        <li><a class="dropdown-item" href="#">Resueltos</a></li>

                      </ul>
                    </div>
                  </div>

                </div>


              </div>

            </div> -->





          </div>
          <div class="card-body ">
            <!-- Los filtros están inacabados -->
            <meetsTable :showFilters="false"/> 

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

// import VsudPagination from "@/components/VsudPagination.vue"
import meetsTable from "@/views/components/Meets/table.vue"

export default {
  name: "meets",
  data() {
    return {

    };
  },
  components: {
    meetsTable,
    // VsudPaginationItem
  },
};
</script>
