<template>
  <div class="readedBy">
    <!-- {{ this.readed_by }} -->
    <svg @click="this.openOrClose()" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-fill" viewBox="0 0 16 16">
      <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"/>
      <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"/>
    </svg>
    <!-- {{ this.users.length }} / {{  this.readed_by.length }} -->
    <ul v-if="open" class="p-absolute readedList">
      <table class="table">
        <thead>
          <tr>
            <th>Usuario</th>
            <th>Leído</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="user in this.users" :key="user.id" >
            <td v-if="this.$store.state.auth.id !== user.id && this.canBeSeen()">{{user.name}}</td>
            <td v-if="this.readed_by.includes(user.id) && this.$store.state.auth.id !== user.id">
              ✔️
            </td>
          </tr>
        </tbody>
      </table>
      <!-- <li v-for="user_id in this.readed_by" :key="user_id">- {{getUserName(user_id)}}</li> -->
    </ul>
  </div>
</template>


<script>
export default {
  props: ['users','message'],
  data() {
    return {
      open: false,
      readed_by:[]
    }
  },
  created() {
    document.addEventListener('click', this.handleClickOutside)
    this.readed_by = JSON.parse(this.message.readed_by)?? []
    // console.log('readed_by',this.readed_by)
  },
  methods: {
    openOrClose() {
      this.open = !this.open
    },
    getUserName(id) {
      var name =""
      this.users.forEach(user => {
        if(user.id == id)
          name = user.name
      });
      return name
    },
    canBeSeen() {

    },
    handleClickOutside(event) {

      const clickedElement = event.target

      // comprueba si el elemento clicado está dentro del componente
      const isClickedInside = this.$el.contains(clickedElement)

      if (!isClickedInside && this.open) {
        // código para ejecutar cuando se hace clic fuera del componente
        this.open = false
      }
    },
  }
}

</script>

<style>
.readedBy {
  max-width: 18px;
  margin: auto;
  margin-right: 0px;
  color: #344767 !important;
}
.readedBy svg{
  transform: translateX(30px);
}
.readedList{
  position: absolute;
  background: white;
  padding: 20px !important;
  z-index: 200;
  border-radius: 10px 0px 10px 10px;
  box-shadow: 0 20px 27px 0 rgba(0, 0, 0, 0.05);
  transform: translateX(-66px);
  /* width: 100%; */
  font-size: 13px;
  line-height: 18px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  
}
</style>
