<template>
  <sidenav
    :class="[
      this.$store.state.isTransparent,
      this.$store.state.isRTL ? 'fixed-end' : 'fixed-start',
    ]"
    v-if="false"
  />
  <Cargador v-if="loading" />
  <div v-if="this.$store.state.notificationsPush.length >=1" class="contieneNotisPush">
    <pushNoti  v-for="(notification,index) in this.$store.state.notificationsPush"  :key="notification.id" :index="index" :notification="notification"  />
  </div>

  <main v-if="!loading"
    class="main-content position-relative max-height-vh-100 h-100 border-radius-lg"
    :style="this.$store.state.isRTL ? 'overflow-x: hidden' : ''"
  >
    <!-- nav -->
    <navbar
      :class="[navClasses]"
      :minNav="navbarMinimize"
      :companiesIn="this.$store.state.general.companies"
      v-if="this.$store.state.showNavbar"
      @reload="reloadMain"
    />
    <Error></Error>
  <div class="container text-center">
    <h1>Nos hemos mudado</h1>
    <h5>Hemos cambiado la URL a <a href="https://app.innoeasesores.com">app.innoeasesores.com</a> </h5>
    <div class="row">
      <div class="col-12">
        <a href="https://app.innoeasesores.com">
          <button class="btn btn-primary">Ir al sitio</button>
        </a>

      </div>
    </div>
    <img src="/switch.svg" alt="">
  </div>
    
    <!-- <router-view class="vista" v-if="!this.loading" :key="this.key" /> -->
    <app-footer v-show="this.$store.state.showFooter" />
  </main>
</template>
<script>
import Sidenav from "./examples/Sidenav";
import Cargador from "@/components/Cargador.vue";
import pushNoti from "@/components/pushNoti.vue";
import Error from "@/views/components/Error.vue";


// import Configurator from "@/examples/Configurator.vue";
import Navbar from "@/examples/Navbars/Navbar.vue";
import AppFooter from "@/examples/Footer.vue";
import { mapMutations } from "vuex";
import cookie from 'cookiejs';
import axios  from 'axios';


// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";




// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCW7RnUid3HLivLFVPAlYK3ua7-qG4g03w",
  authDomain: "innoe-test.firebaseapp.com",
  projectId: "innoe-test",
  storageBucket: "innoe-test.appspot.com",
  messagingSenderId: "795867377926",
  appId: "1:795867377926:web:96de605cb66c41541e2e87",
  measurementId: "G-13GBE1PSJL"
};

// Initialize Firebase
const appFirebase = initializeApp(firebaseConfig);


const messaging = getMessaging(appFirebase);
//
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';






export default {
  name: "App",
  components: {
    Sidenav,
    // Configurator,
    Navbar,
    AppFooter,
    Cargador,
    pushNoti,
    Error
  },
  data() {
    return {
      loading: true,
      notifications : [],
      device_token: null,
      auth: null,
      key:1
    }
  },
  mounted() {

    var alex = null
    // onMessage(messaging, (payload) => {
    //   this.notificate(payload)
    //   console.log('Mensaje recibido On message ', payload);
    //   // var audio = new Audio('/sound.wav');
    //   // audio.volume = 0.8; // establece el volumen en 50%
    //   // audio.play();
    // });

    // TOKEN de Notificaciones de FIrebase
    getToken(messaging, { vapidKey: 'BImbGlvJDXyr-5AOi19HHjNReoTbTjyMw9tiHaaGwpYOh9vAduHVCDRebVXjP1pL1eNvFFOd-2rLwEHvzS4NzRM' })
    .then((currentToken) => {
      if (currentToken) {
        alex = currentToken;
        this.device_token = alex;
        this.$store.state.device_token = alex
        // Send the token to your server and update the UI if necessary
        console.log('TOKEN de dispositivo',alex)

        // ...
      } else {
        // Show permission request UI
        // alert('No tienes permiso para Notificaciones');
        console.log('No registration token available. Request permission to generate one.');
        // ...
      }
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
      // ...
    })
    .finally(() => {
      // comprovamos si hay token, cogemos los datos, iniciamos socket y nos conectamos
      this.checkToken()
      // this.getGeneralData()


    });




  },
  methods: {
    reloadMain() {
       this.key++
    },
    ...mapMutations(["toggleConfigurator", "navbarMinimize"]),
    // Valida si hay token, en caso negativo enviamos al login y en caso positivo llamamos al /user
    checkToken() {
      var c = cookie.get('authToken')
      if((c == false || c == undefined) && this.$route.name !== "Sign In")
        this.$router.push({ name: 'Sign In' });
      this.validateToken(c)
    },
    notificate(notificationIn) {
      const notiUrl = '/'+notificationIn.url
      // si estamos en el propio chat no suena
      if(this.$route.path !== notiUrl ) {
        var audio = new Audio('/sound.wav');
        audio.volume = 0.8;   
        audio.play();
        this.$store.state.notificationsPush.push({
          id: notificationIn.id,
          title: notificationIn.title,
          body: notificationIn.body,
          data: notificationIn.body,
          url:notificationIn.url
        })
      }
        
    },
    validateToken(token) {
      console.log('EOTOKEN',this.device_token)
      this.$store.state.token = token
      axios.defaults.headers.common['Authorization'] = 'Bearer '+token
      var self = this
      axios.post(this.$store.state.baseUrl+'/api/user', {
        'device_token': this.device_token
      })
        .then(response => {
          if(response.data.rc == 1) {
            this.$store.state.auth = response.data.data
            this.auth = response.data.data
            
            if(this.$route.name == "Sign In")
             this.$router.push({ name: '/' });
          } else {
            this.$router.push({ name: 'Sign In' });
          }
        })
        .catch(() =>  {
           console.error('ERROR DE TOKEN')
           self.$router.push({ name: 'Sign In' });
           
        })
        .finally(()=>{
          this.getGeneralData();
          this.loading = false;
        })

    },
    getGeneralData() {
      // alert('general')
      console.log('get general dta')
      // if(!this.$store.state.auth)
      //   return;
      axios.defaults.headers.common['Authorization'] = 'Bearer '+this.$store.state.token
      axios.get(this.$store.state.baseUrl+'/api/general', null)
        .then(response => {
          if(response.data.rc == 1) {
            this.$store.state.general = response.data.data
            console.log('GENERAL',this.$store.state.general)
            // si todo va bien entonces iniciamos y conectamos sockets
            this.initSocket()
            this.connectSocket()
            // si solo tiene una empresa se le asigna al store
            this.assignCompany(response.data.data.companies)

          }
        })
        .catch(() =>  {
           console.error('error con formularios y datos')
        })
    },
    assignCompany(companies) {
      if(companies.length == 1) {
        this.$store.state.currentCompany = companies[0]
        this.$store.state.currentCompany_id = companies[0].id

      }
    },
    initSocket() {
      console.log('initSocket')
      // como ya tenemos los datos del usuario ya podemos hacer el global de la conexión
      window.Pusher = Pusher;
      window.Echo = new Echo({
        broadcaster: 'pusher',
          key: this.$store.state.socketsKey,
          wsHost: this.$store.state.socketsUrl,
          wssHost: this.$store.state.socketsUrl,
          wsPort: this.$store.state.socketsPort,
          wssPort: this.$store.state.socketsPort,
          disableStats: true,
          cluster:'eu',
          forceTLS: this.$store.state.socketsTLS,
          enabledTransports: this.$store.state.socketsTransPorts,
          authEndpoint: this.$store.state.baseUrl+'/api/broadcasting/auth',
          auth: {
            headers: {
              Authorization: `Bearer `+ this.$store.state.token
            }
          }
      });
      this.$store.state.initSocket = true
    },
    // nos conectamos al canal del Usuario que pertoca, que Es User.{id} o App
    connectSocket() {
      console.log('conexion eventsocket')
      this.$store.state.socketChat = window.Echo
          .join("User."+this.$store.state.auth.id)
          .listen('NotificationEvent', (e) => {
            console.log('NotificationEvent',e)
            this.notificate(e)
            if(e.type == "chat") {
              console.log('AÑADOOOO',e.message)
              this.$store.state.general.pendingMessages.push(e.message);
            } else {
              // alert('he')
              this.$store.state.general.notifications.push(e);
            }
            // this.notificate(e)

          });


    }

  },
  computed: {
    navClasses() {
      return {
        "position-sticky blur shadow-blur mt-4 left-auto top-1 z-index-sticky": this
          .$store.state.isNavFixed,
        "position-absolute px-4 mx-0 w-100 z-index-2": this.$store.state
          .isAbsolute,
        "px-0 mx-4 mt-4": !this.$store.state.isAbsolute,
      };
    },
  },
  beforeMount() {
    this.$store.state.isTransparent = "bg-transparent";
  },
};
</script>
