<template>
  <div :id="'comment'+comentario.id" class="col-12 mt-2">
    <div class="card">
      <div class="card-body">
        <div class="d-inline-flex">

          <div class="media-left m-2 media-top imgComment">
            <img :src="url" class="media-object " style="width:70px">
          </div>

          <div class="media-body m-auto">
            <div class="infoUserComment">
              <span class="w-100 mt-2 text-center font-weight-bold" >{{comentario.user.name}}</span>
              <span v-if="comentario.user.rol=='asesor'" class="w-100 text-center"> - Asesor</span>
            </div>
            <span class="horaPeke">{{this.$store.state.dateFormat(comentario.created_at)}}</span>


            <p>{{comentario.content}}</p>


            <div v-if="comentario.request_form !== null" class="contieneFormAdjuntos">
              <div class="formularioAdjunto mt-2">

                <router-link
                  :to="{
                    name: 'Solicitud de Formulario',
                    params: {
                      id: comentario.request_form.id
                    }
                  }"

                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-file-earmark-text-fill" viewBox="0 0 16 16">
                    <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM4.5 9a.5.5 0 0 1 0-1h7a.5.5 0 0 1 0 1h-7zM4 10.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm.5 2.5a.5.5 0 0 1 0-1h4a.5.5 0 0 1 0 1h-4z"/>
                  </svg>
                  <span class="m-1">Formulario: {{comentario.request_form.form.title}}</span>
                </router-link>
              </div>

            </div>
            <div v-if="comentario.document !== null" class="contieneFormAdjuntos">
              <div class="formularioAdjunto mt-2">

                <router-link
                  :to="{
                    name: 'Archivo',
                    params: {
                      id: comentario.document.id
                    }
                  }"

                >
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-folder-fill" viewBox="0 0 16 16">
                  <path d="M9.828 3h3.982a2 2 0 0 1 1.992 2.181l-.637 7A2 2 0 0 1 13.174 14H2.825a2 2 0 0 1-1.991-1.819l-.637-7a1.99 1.99 0 0 1 .342-1.31L.5 3a2 2 0 0 1 2-2h3.672a2 2 0 0 1 1.414.586l.828.828A2 2 0 0 0 9.828 3zm-8.322.12C1.72 3.042 1.95 3 2.19 3h5.396l-.707-.707A1 1 0 0 0 6.172 2H2.5a1 1 0 0 0-1 .981l.006.139z"/>
                </svg>
                  <span class="m-1">Documento: {{comentario.document.name}}</span>
                </router-link>
              </div>

            </div>
            <div  class="contieneOpcionComentarBoton mt-3">
              <button class="btn btn-link" type="button" @click="showAddNewComment = !showAddNewComment" name="button"> Comentar</button>

            </div>
          </div>

        </div>
        <addComment v-if="this.showAddNewComment" :dataShowAddNewComment="showAddNewComment" :parent_comment="comentario.id" :comentarios="comentario.comments"/>
      </div>
      <Cargador v-if="false"/>

    </div>
    <div v-if="comentario.comments.length !== 0" class="row">
      <div class="col-2 indicadoresAnteriores">
        <div class="indicadorAnterior1"></div>
        <div class="indicadorAnterior2"></div>
      </div>
      <div class="col-10">
        <Comments v-for="newComment in comentario.comments"  :key="newComment.id" :comentario="newComment" />
      </div>
    </div>

  </div>
</template>

<script>
import Cargador from "@/components/Cargador.vue";
import addComment from "@/views/components/Tickets/addComment.vue";
// import Comments from "@/views/components/Tickets/comments.vue";


// import VsudPagination from "@/components/VsudPagination.vue"
// import VsudPaginationItem from "@/components/VsudPaginationItem.vue"

export default {
  name: "tickets-table",
  data() {
    return {
      loading:true,
      url: this.$store.state.baseUrl+"/images/profiles/default.png",
      showAddNewComment: false

    };
  },
  props: {
    comentario: {

    }
  },
  components: {
    Cargador,
    addComment,

    // VsudAvatar,
    // Comments

    // VsudPagination,
    // VsudPaginationItem
  },
  mounted(){
    this.getUrl()
  },
  methods :{
    getUrl() {
      if(this.comentario.user.profile_image !== null)
        this.url = this.comentario.user.profile_image.url+'/100.'+this.comentario.user.profile_image.format
    }
  }


};
</script>
