<template>
  <div class="">

    <Cargador v-if="loading"/>
    <div v-else class="container">
      <div v-if="error" class="alert alert-warning mt-3">
        Parece que hay algun error o no tienes permiso
      </div>
      <div v-if="mustSelectCompany" class="mt-2 alert alert-warning">
        <h5>Debes seleccionar una empresa</h5>
      </div>
      
      
      
      <div
        class="mt-4 page-header min-height-300 border-radius-xl"
        :style="{
          backgroundImage:
            'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')',
          backgroundPositionY: '50%',
        }"
      >
        <span class="mask bg-gradient-success opacity-6"></span>
      </div>
      <div class="mx-4 overflow-hidden card card-body blur shadow-blur mt-n6">
        <div class="row gx-4">
          <div class="col-auto">
            <div class="avatar avatar-xl position-relative overflow-hidden">
              <img
                :src="this.url"
                alt="profile_image"
                class="shadow-sm w-100 border-radius-lg"
              />
            </div>
          </div>
          <div class="col-auto my-auto">
            <div class="h-100">
              <h5 class="mb-1">{{info.name}}</h5>
              <p  v-if="info.rol =='asesor'" class="mb-0 text-sm font-weight-bold">Asesor / {{info.department? info.department.name:''}}</p>
              <p  v-if="info.rol =='admin'" class="mb-0 text-sm font-weight-bold">Administrador</p>
              <div  v-if="info.rol =='client'" class="mb-0 text-sm font-weight-bold">
                <div v-if="info.companies.length >1">Usuario / Empresa</div>
                <div v-else-if="info.companies[0].type == 'autonomous'">Usuario / Autónomo</div>
                <div v-else>Usuario / Cliente</div>
              </div>

            </div>
          </div>
          <Buttons :user="info"/>
        </div>
      </div>



      
      <div v-if="this.info.rol == 'client'" class="row mt-4">
        
        <Info v-if="info.companies.length == 1" class="col-md-12"  :info="this.info.info"/>
        <Info v-else class="col-md-8"  :info="this.info.info"/>


        <div v-if="this.info.companies.length >1" class="col-md-4">
            <div class="card">
              <div class="card-body">
                <h4>Empresas: </h4>
                <ul>
                  <li v-for="(company) in this.info.companies" :key="company.id" >
                    <router-link
                    :to="{
                      path: '/company/'+company.id
                    }"
                    >
                    {{company.name}}
                  </router-link>
                  
                  </li>
                </ul>
              </div>
            </div>
          </div>


        
      </div>

    </div>

  </div>


</template>
<style>
p {
  margin-bottom: 4px !important;
}
</style>
<script>
import Cargador from "@/components/Cargador.vue";
import Buttons from "@/views/components/Profile/buttons.vue";

import Info from "@/views/components/User/Info.vue";
import cookie from 'cookiejs';
import axios  from 'axios';


export default {
  name: "ProfileOverview",
  components: {
    Buttons,
    Cargador,
    Info

  },
  data() {
    return {
      loading:true,
      error:false,
      mustSelectCompany: false,
      url:this.$store.state.baseUrl+"/images/profiles/default.png",
      info: {},
      selection: null
    };
  },
  beforeCreate(){
    // this.$store.state.ownKey++;
  },
  mounted() {
    this.getInfo();

  },
  methods: {
    getInfo() {
      var token = this.$store.state.token

      axios.defaults.headers.common['Authorization'] = 'Bearer '+token
      axios.get(this.$store.state.baseUrl+'/api/user/'+this.$route.params.id, null)
        .then(response => {
          if(response.data.rc == 1) {
            this.info = response.data.data
            if(this.info.profile_image == null) {
              this.url = this.$store.state.baseUrl+"/images/profiles/default.png"
            } else {
              this.url = this.info.profile_image.url+'/100.'+this.info.profile_image.format
            }
            // el primer selection
            this.selection = "Identificación"


          } else {
            this.error = true
          }
        })
        .catch(() => {
          this.error = true

        })
        .finally(() => {
          this.loading = false;

        })

    },
    changeApartado(apartado) {
      this.selection = apartado

    },
    SolChat(){
      console.log(this.info.rol,'profile')
      var self = this;
      var token = this.$store.state.token
      axios.defaults.headers.common['Authorization'] = 'Bearer '+token
      let formData = new FormData();
      // si el usuario consultado es asesor con y el actual cliente sola empresa es fácil, en caso de dos se habrá de seleccionar 
      if(this.$store.state.auth.rol == 'client') {
        if(this.$store.state.currentCompany_id)
          formData.append('company_id',  this.$store.state.currentCompany_id);
        else 
          this.mustSelectCompany = true;
        

      } else {
        // si la persona que solicitamos es empresa y solo tiene una
        if(this.info.rol == 'client' && this.info.companies.length == 1)
          formData.append('company_id',  this.info.companies[0].id);
      }
      if(this.info.rol == 'asesor') 
          formData.append('asesor_id',  this.info.id);
      
      
      axios.post(this.$store.state.baseUrl+'/api/chat/solicitar', formData)
        .then(response => {
          if(response.data.rc == 1) {
            // window.location.href = '/chats/'+response.data.data.id
            this.$router.push({path: '/chats/'+response.data.data.id })

          }
        })
        .catch(function () {
            self.error = true
        })
        .catch(function () {
            self.loading = false
        })
    },
    logout() {
      this.loading = true;

      var self = this;
      var token = this.$store.state.token
      axios.defaults.headers.common['Authorization'] = 'Bearer '+token
      axios.post(this.$store.state.baseUrl+'/api/logout', {
        device_token: self.$store.state.device_token
      })
        .then(response => {
          if(response.data.rc == 1) {
            this.$store.state.auth = false;
            this.$store.state.token = false;
            // add token to cookie
            cookie.set("authToken",false,{expires: 365});
            // push the router to home
            // this.$router.push({ name: "/" }); Así no hace un refresh y no carga el usuario
            window.location.href = '/login';

          }
        })
        .catch(function () {
            self.error = true
        })
        .catch(function () {
            self.loading = false
        })

    }
    // requestMeet(){
    //   this.$router.push({
    //     name: 'Solicitar Reunión',
    //       params: {
    //         ticket_id: this.ticket.id
    //       },
    //       props: true
    //     })
    // },
  }

};
</script>
