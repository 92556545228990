<template>
  <div class="d-flex justify-content-center">
    <img :width="width?? 100 + 'px'" src="/loading.gif" alt="">
  </div>
</template>

<script>
export default {
  name: "Cargador",
  props: {
    width: {
      type: Number,
      default: 100
    }
  }
};
</script>
