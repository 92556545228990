<template>
  <div class="py-4 container-fluid mt-3">

    <div  class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <h5>Subir Documento
            <div v-if="this.form.chat_id" >
              <span>
                Documento adjunto a Chat
              </span>
              <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" class="bi bi-chat-dots-fill ms-2" viewBox="0 0 16 16">
                <path d="M16 8c0 3.866-3.582 7-8 7a9.06 9.06 0 0 1-2.347-.306c-.584.296-1.925.864-4.181 1.234-.2.032-.352-.176-.273-.362.354-.836.674-1.95.77-2.966C.744 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7zM5 8a1 1 0 1 0-2 0 1 1 0 0 0 2 0zm4 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0zm3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
              </svg>
            </div>
          </h5>
            <form @submit.stop.prevent="uploadDocument()">

            <div class="row">
              <div class="col-md-5">
                <div class="form-group">
                  <label for="formFile" class="form-label">Archivo</label>
                  <input
                    class="form-control"
                    type="file"
                    accept="application/pdf, application/msword, .csv, .xlsx, .doc, .pages"
                    ref="fileInput"
                    required
                    @change="handleFileUpload( $event )"  
                  />
                </div>
                

                <div class="form-group">
                  <label for="exampleInputEmail1">Descripción </label>
                  <textarea v-model="form.description" class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                </div>

                <div class="form-group">
                  <label for="exampleInputEmail1">Año </label>
                  <input v-model="form.year" class="form-control" type="number" min="1900" max="2099" step="1"  />
                </div>


                <div class="form-group">
                  <label>Atributos</label>
                  <div v-for="attribute in this.$store.state.general.attributes"  :key="attribute.id" >
                    <!-- Solo los atributos que no son años -->
                    <div v-if="!attribute.is_year" class="form-check">
                      <input v-model="form.attributes" class="form-check-input" type="checkbox" :value="attribute.id" :id="'check'+attribute.id">
                      <label class="form-check-label" :for="'check'+attribute.id">
                        {{attribute.name}}
                      </label>
                    </div>
                  </div>

                </div>







                
                <div v-if="this.$store.state.auth.rol !== 'client'" class="form-group">
                  <label for="exampleInputEmail1">Fecha de Aviso</label>
                  <input v-model="form.alert_on" type="date" class="form-control">
                </div>
              </div>
              <div class="col-md-7">
                
                <div class="form-group">
                  <label for="exampleInputEmail1">Título Documento</label>
                  <input required v-model="form.name" type="text" class="form-control">
                </div>
                
                <div  class="form-group">
                  <label for="exampleInputEmail1">Usuario del documento</label>
                  <searchUsers :requiredClient="true" :to_id="form.to_id" v-on:update:to_id="form.to_id = $event" :onlyClients="true"/>
                </div>
                <div v-if="this.$store.state.auth.rol !== 'client'" class="form-check">
                  <input v-model="form.visible" class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                  <label class="form-check-label" for="flexCheckDefault">
                    Archivo Visible a Cliente
                  </label>
                </div>
                <small v-if="form.visible == false">El archivo solo será visible por los Asesores</small>
                <div v-if="form.to_id !== null" class="form-group row">
                  <div class="col-md-3">
                    <label for="">Departamento</label>
                    <select @change="changeHelper()" required v-model="form.department_id" class="form-select" name="cars" id="cars">
                      <option  v-for="department in this.$store.state.general.departments"  :key="department.id" :value="department.id">{{department.name}}</option>
                    </select>
                  </div>
                  <div class="col-md-9">
                    <label for="">Carpeta</label>
                      <input @keyup="searchCurrent()"  v-model="form.path" type="text" class="form-control">
                  </div>
                  <div class="">
                    <label for="">Carpetas dentro : <Cargador v-if="this.loadingHelper"/> </label>

                    <div class="mt-0">
                      <button type="button" class="btn btn-secondary m-2" v-for="folder in this.helper_folders"  :key="folder.id" @click="addFolderToPath(folder)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-folder-fill" viewBox="0 0 16 16">
                          <path d="M9.828 3h3.982a2 2 0 0 1 1.992 2.181l-.637 7A2 2 0 0 1 13.174 14H2.825a2 2 0 0 1-1.991-1.819l-.637-7a1.99 1.99 0 0 1 .342-1.31L.5 3a2 2 0 0 1 2-2h3.672a2 2 0 0 1 1.414.586l.828.828A2 2 0 0 0 9.828 3zm-8.322.12C1.72 3.042 1.95 3 2.19 3h5.396l-.707-.707A1 1 0 0 0 6.172 2H2.5a1 1 0 0 0-1 .981l.006.139z"/>
                        </svg>
                        {{folder.name}}
                      </button>
                    </div>


                  </div>

                </div>

              </div>
            </div>
            <div class="row">
              <div class="d-flex text-right">
                <button  type="submit" class="btn btn-primary" name="button">Guardar</button>
                  <Cargador class="m-3" v-if="this.loading"/>
              </div>
              <div v-if="error" class="alert">
                <div class="alert alert-danger">
                  Parece que hay algún error
                </div>
              </div>


            </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cargador from "@/components/Cargador.vue";

import axios  from 'axios';

import searchUsers from "@/components/SearchUsers.vue";

// import VsudPagination from "@/components/VsudPagination.vue"
// import VsudPaginationItem from "@/components/VsudPaginationItem.vue"

export default {
  name: "Solicitud de formulario",
  data() {
    return {
      loading:false,
      loadingHelper:false,
      error: false,
        form: {
          name:null,
          description:null,
          department_id:null,
          alert_on: null,
          visible: true,
          to_id:null,
          file: null,
          attributes:[],
          year:new Date().getFullYear(),
          path: "",
          chat_id: null
        },
      helper_folders: null,
      folders_arrays: [],
      current_folder:null



    };
  },
  mounted() {
    if(this.$route.query.chat_id !== undefined) {
      // si es cliente deberemos recoger su empresa
      if(this.$store.state.auth.rol == 'client') {
        this.form.to_id = this.$store.state.currentCompany_id
      } 
        this.form.chat_id = this.$route.query.chat_id
      
    }
      
   
  },

  components: {
    Cargador,
    searchUsers

  },

  methods: {
    handleFileUpload( event ){
      this.form.file = event.target.files[0];
      if( this.form.name == null || this.form.name == "") {
        var path = this.form.file.name
        this.form.name = path.split(/[\\/]/).pop().replace(/\.[^/.]+$/, '')

      }
        
    },
    searchCurrent() {
      this.folders_arrays = this.form.path.split("/");
    },
    addFolderToPath(folder) {
      // añadimos al path la nueva carpeta y preguntamos por las subcarpetas de éstas
      console.log(folder,'foldeer')
      this.form.path = this.form.path+'/'+folder.name
      this.current_folder = folder.id
      this.changeHelper(false);
    },

    // actualiza el helper, que es quien indica las carpetas existentes de una carpeta
    changeHelper(reset=true) {
      if(reset){
        this.current_folder = null
        this.form.path = '';
      }
      this.loadingHelper = true
      var token = this.$store.state.token
      axios.defaults.headers.common['Authorization'] = 'Bearer '+token
      axios.post(this.$store.state.baseUrl+'/api/folder/search', {
        department_id: this.form.department_id,
        to_id: this.form.to_id,
        folder_id:this.current_folder
      })
        .then(response => {
          if(response.data.rc == 1) {
            this.helper_folders = response.data.data;
            this.loadingHelper = false
            // console.log(response.data.data,'HELPER')


          }
        })
    },
    uploadDocument(){
      if(this.loading)
        return
      this.loading = true;
      let formData = new FormData();
      formData.append('file',         this.form.file);
      formData.append('name',         this.form.name);
      if(this.form.description !== null)
        formData.append('description',  this.form.description);
      if(this.form.alert_on !== null)
        formData.append('alert_on',     this.form.alert_on);
      if(!this.form.visible)
        formData.append('visible',      false);
      if(this.form.chat_id)
        formData.append('chat_id', this.form.chat_id);
      if(this.$store.state.auth.rol == 'client')
        formData.append('company_id', this.$store.state.currentCompany_id);


      formData.append('to_id',        this.form.to_id);
      formData.append('department_id',this.form.department_id);
      if(this.form.path !== '' && this.form.path)
        formData.append('path',this.form.path);

      formData.append('attributes',this.form.attributes);

      var self = this;
      var token = this.$store.state.token
      axios.defaults.headers.common['Authorization'] = 'Bearer '+token
      axios.post(this.$store.state.baseUrl+'/api/document/upload', formData)
        .then(response => {
          if(response.data.rc == 1) {
            if(self.form.chat_id !== null)
              this.$router.push({path: '/chats/'+self.form.chat_id })
            else 
            window.location.href = '/documentacion/archivo/'+response.data.data.id;

          }
        })
        .catch(function () {
            self.error = true
        })
        .finally(function () {
            self.loading = false
        })
    }
  }
};
</script>
