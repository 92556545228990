<template>
  <div class="row ">

    <div class="col-3 ">
      <div v-if="data.ticket">
        <router-link class="btn btn-secondary" :to="{
          path: '/tickets/'+data.ticket.id,
          }">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-ticket-detailed-fill me-1" viewBox="0 0 16 16">
              <path d="M0 4.5A1.5 1.5 0 0 1 1.5 3h13A1.5 1.5 0 0 1 16 4.5V6a.5.5 0 0 1-.5.5 1.5 1.5 0 0 0 0 3 .5.5 0 0 1 .5.5v1.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 11.5V10a.5.5 0 0 1 .5-.5 1.5 1.5 0 1 0 0-3A.5.5 0 0 1 0 6V4.5Zm4 1a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 0-1h-7a.5.5 0 0 0-.5.5Zm0 5a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 0-1h-7a.5.5 0 0 0-.5.5ZM4 8a1 1 0 0 0 1 1h6a1 1 0 1 0 0-2H5a1 1 0 0 0-1 1Z"/>
            </svg>
          <span >Asunto: </span>{{data.ticket.title}}
        </router-link>
      </div>
    </div>

    <div class="col-6 text-bold text-center text-xl titleChat ">
      <div v-if="data.despacho">
        <div>Despacho</div>
      </div>
      <div v-else>
        <div v-if="(data.company) && this.$store.state.auth.rol !== 'client'">
          <small class="text-xs">Empresa</small>
          
          <div>
            <router-link
                      :to="{
                      name: 'Empresa',
                      params: {
                        id: data.company.id
                      }

                    }">
            {{ data.company.name }} 
            </router-link>
          </div>
        </div>
        <div v-else-if="data.ticket">
          Asunto:  {{data.ticket.title}}
        </div>
        <div v-else>
          {{this.getOtherUserName()}}
        </div>
      </div>
      
      
    </div>


    <div class="col-3 text-right">
      <svg @click="openMen()" xmlns="http://www.w3.org/2000/svg" width="30" height="20" fill="currentColor" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
        <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
      </svg>
      <div  ref="myDiv" class="optionChatsCard text-left" v-if="open">
        <div v-if="this.$store.state.auth.rol=='asesor' && data.company && !data.despacho && data.ticket == null">
          <router-link :to="{
            name: 'Abrir Asunto',
            
            query: {
              to_id: data.company.id,
              chat_id: data.id,
              name_to: data.company.name
            },
            props: true

          }">
            Abrir Asunto
          </router-link>

        </div>
        
        <div class="mt-1">
          <a @click="showParticipants()" href="#">Participantes del chat</a>
        </div>
        <div class="mt-1">
          <router-link v-if="data.company" :to="{
            name: 'Subir Documento',
            
            query: {
              to_id: data.company.id?? null,
              chat_id: data.id,
            },
            props: true

          }">
          Enviar Documento
          </router-link>
          <router-link v-else :to="{
            name: 'Subir Documento',
            query: {
              chat_id: data.id,
            },
            props: true

          }">
          Enviar Documento
          </router-link>
        </div>
      </div>
    </div>

    <div v-if="show" class="col-md-12 text-sm mt-1">
      <text v-for="user in data.users"  :key="user.id" >
        <router-link :to="{
          'path':'/user/'+user.id
        }">
        {{user.name}}  &nbsp;
        </router-link>
        
      </text>
    </div>

  </div>

</template>

<script>





export default {
  name: "headerChat",
  props: ['data'],
  data() {
    return {
      open: false,
      show: false
    }
  },
  beforeUnmount() {
    // Elimina el listener al desmontar el componente
    document.removeEventListener('click', this.handleOutsideClick);
  },
  mounted() {
    document.addEventListener('click', this.handleOutsideClick);
  },
  methods: {
    getOtherUserName() {
      if(this.$store.state.auth.rol == 'client'){
        var name = "";
        this.data.users.forEach(user => {
          if(user.rol == 'asesor')
            name = user.name
        });
      } else {
        this.data.users.forEach(user => {
          if(user.id !== this.$store.state.auth.id)
            name = user.name
        });
      }
      return name;

      // return this.data;
      // return "keloke";
      // var name
      
    },
    openMen() {
      this.open = !this.open
    },
    handleOutsideClick(event) {
        // Verifica si el evento de clic ocurrió dentro del componente
        if (this.$el.contains(event.target)) {
        return;
        }
        this.open = false;
        
    },
    showParticipants(){
      this.show = !this.show
      this.open = !this.open
    }
  }
};
</script>
