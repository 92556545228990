import { createStore } from "vuex";
// import bootstrap from "bootstrap/dist/js/bootstrap.min.js";


function addZero(i) {
  if (i < 10) {i = "0" + i}
  return i;
}

function getUrl() {
  if(process.env.NODE_ENV == 'development')
    return "http://127.0.0.1:8000"
  if(process.env.NODE_ENV == 'production')
    return "https://api-app-version-0.innoeasesores.com"

}

function getSocketsUrl() {
  if(process.env.NODE_ENV == 'development')
    return "127.0.0.1"
  if(process.env.NODE_ENV == 'production')
    return "sockets.innoeasesores.com"
  
}

function getSocketsTLS() {
  if(process.env.NODE_ENV == 'development')
    return false
  if(process.env.NODE_ENV == 'production')
    return true
}

function getSocketsKey() {
  if(process.env.NODE_ENV == 'development')
    return 123
  if(process.env.NODE_ENV == 'production')
    return 256789123
}

function getSocketsTransports() {
  if(process.env.NODE_ENV == 'development')
    return ['ws']
  if(process.env.NODE_ENV == 'production')
    return ['wss', 'ws', 'flash']
}



// console.log('AHORA',process.env)

export default createStore({
  state: {
    hideConfigButton: false,
    isPinned: true,
    showConfig: false,
    isTransparent: "",
    isRTL: false,
    color: "",
    isNavFixed: true,
    isAbsolute: false,
    showNavs: true,
    showSidenav: true,
    showNavbar: true,
    showFooter: true,
    showMain: true,
    ownKey: 1,
    initSocket: false,
    device_token: null,
    notificationsPush: [],
    dateFormat: (date)=> {
      date = new Date(date);
      var hoy = new Date();
      if(date.getFullYear() === hoy.getFullYear() &&
      date.getMonth() === hoy.getMonth() &&
      date.getDate() === hoy.getDate())
      {
        return '\n'+addZero(date.getHours())+":"+addZero(date.getMinutes())
      } else {
        return date.getDate()+'/'+(date.getMonth()+1)+'/'+date.getFullYear()+'\n'+addZero(date.getHours())+":"+addZero(date.getMinutes())
      }
    },

    alertar: () => {
      alert('hey')
    },
    pendingNotis(n) {
      return n.filter(noti => {
        return noti.read == false
      })
    },
    navbarFixed:
      "position-sticky blur shadow-blur left-auto top-1 z-index-sticky px-0 mx-4",
    absolute: "position-absolute px-4 mx-0 w-100 z-index-2",
    // bootstrap,
    baseUrl: getUrl(),
    socketsUrl: getSocketsUrl(),
    socketsPort: 6001,
    socketsKey: getSocketsKey(),
    socketsTransPorts: getSocketsTransports(),
    socketsTLS: getSocketsTLS(),
    auth: false,
    token: false,
    loadingChats: false, // para el listado de chats cosas internas
    //general data like departments or forms
    general: {
      departments:[],
      forms:[],
      companies: [],
      asesores: [],
      notifications: [],
      pendingMessages: [],
      chats: []
    },
    // all related to chats
    chats: {
      form: {
        search: "",
      },
      list: []
    },
    notifications:[],
    errores:[],
    currentCompany_id: null,
    currentCompany: null
  },
  mutations: {
    toggleConfigurator(state) {
      state.showConfig = !state.showConfig;
    },
    navbarMinimize(state) {
      const sidenav_show = document.querySelector(".g-sidenav-show");
      if (sidenav_show.classList.contains("g-sidenav-hidden")) {
        sidenav_show.classList.remove("g-sidenav-hidden");
        sidenav_show.classList.add("g-sidenav-pinned");
        state.isPinned = true;
      } else {
        sidenav_show.classList.add("g-sidenav-hidden");
        sidenav_show.classList.remove("g-sidenav-pinned");
        state.isPinned = false;
      }
    },
    sidebarType(state, payload) {
      state.isTransparent = payload;
    },
    cardBackground(state, payload) {
      state.color = payload;
    },
    navbarFixed(state) {
      if (state.isNavFixed === false) {
        state.isNavFixed = true;
      } else {
        state.isNavFixed = false;
      }
    },
    toggleEveryDisplay(state) {
      state.showNavbar = !state.showNavbar;
      state.showSidenav = !state.showSidenav;
      state.showFooter = !state.showFooter;
    },
    toggleHideConfig(state) {
      state.hideConfigButton = !state.hideConfigButton;
    },
  },
  actions: {
    toggleSidebarColor({ commit }, payload) {
      commit("sidebarType", payload);
    },
    setCardBackground({ commit }, payload) {
      commit("cardBackground", payload);
    },
  },
  getters: {},
});
