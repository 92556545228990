<template>
  <div class="py-4 container-fluid mt-3">
    <div v-if="!loading && error" class="alert">
      <div class="alert alert-danger">
        Parece que hay algún error
      </div>
    </div>
    <Cargador v-if="loading"/>
    <div v-if="!loading && !error" class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <h4>Información del formulario</h4>
            <h6>Identificador:  #000{{info.id}}</h6>
            <h6>Título del formulario: {{info.form.title}}</h6>
            <h6>Descripción: </h6>
            <p>{{info.form.description}}</p>
            <div class="d-flex">
              <div class="m-3">
                <h6>Enviado por:</h6>
                <vsud-avatar
                  size="xl"
                  border-radius="lg"
                  alt="user1"
                  :user="info.from"
                  :circular="true"
                />
              </div>
             <div v-if="info.company" class="m-3">
                <h6>Enviado a:</h6>
                <AvatarCompany 
                  size="xl"
                  border-radius="lg"
                  alt="user1"
                  :company="info.company"
                  :circular="true"
                  />
              </div>
            </div>

            <h6>Estado:</h6>
            <!-- Para clientes -->
            <div v-if="this.$store.state.auth.rol == 'client'" class="">
              <span v-if="info.status == 'success' || info.status == 'answered'"><i class="fa fa-circle me-1 m-1 success"></i>Contestado</span>
              <span v-if="info.status == 'pending'"><i class="fa fa-circle me-1 m-1 pending"></i>Pendiente</span>
              <span v-if="info.status == 'error'"><i class="fa fa-circle me-1 m-1 warning"></i>Con Errores</span>
            </div>
            <!-- Asesores -->
            <div v-else>
              <span v-if="info.status == 'success'"><i class="fa fa-circle me-1 m-1 success"></i>Correcto</span>
              <span v-if="info.status == 'answered'"><i class="fa fa-circle me-1 m-1 pending"></i>Contestado, pendiente de validar</span>
              <span v-if="info.status == 'pending'"><i class="fa fa-circle me-1 m-1 pending"></i>Pendiente de Cliente</span>
              <span v-if="info.status == 'error'"><i class="fa fa-circle me-1 m-1 warning"></i>Con Errores</span>
              <br>
              <small class="mt-3">Los estados son: Pendiente, contestado y pendiente de validar, Error o Correcto</small>
            </div>

            <div v-if="info.ticket !== null" class="mt-3">
              <router-link
                :to="{
                  name: 'Ticket',
                  params: {
                    id: info.ticket.id
                  }
                }">
                <button class="btn btn-info" type="button" name="button">
                  Ir a Asunto Asociado <br> #000{{info.ticket.id}}
                </button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!loading && !error" class="row mt-3">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <h4>Respuestas del formulario</h4>

              <div v-for="field in info.form.fields"  :key="field.id" class="form-group">
                <LabelForm :fieldProp="field" :requestForm_id="info.id"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import Cargador from "@/components/Cargador.vue";
import LabelForm from "@/views/components/Forms/field.vue";
import VsudAvatar from "@/components/VsudAvatar.vue";
import AvatarCompany from "@/components/VsudAvatarCompany.vue";

import axios  from 'axios';

// import VsudPagination from "@/components/VsudPagination.vue"
// import VsudPaginationItem from "@/components/VsudPaginationItem.vue"

export default {
  name: "Solicitud de formulario",
  data() {
    return {
      loading:true,
      error: false,
      info: {
        form: {

        }
      },
      answers: {

      }

    };
  },

  components: {
    Cargador,
    LabelForm,
    VsudAvatar,
    AvatarCompany
    // VsudPagination,
    // VsudPaginationItem
  },
  mounted() {


    this.getInfo()

  },
  methods: {
    getInfo(){
      
      var token = this.$store.state.token
      axios.defaults.headers.common['Authorization'] = 'Bearer '+token
      axios.get(this.$store.state.baseUrl+'/api/request_form/'+this.$route.params.id, null)
        .then(response => {
          if(response.data.rc == 1) {

            this.info = response.data.data
            

          } else {
            this.error = true
          }
        })
        .catch( () => {
            this.error = true
        })
        .finally( () => {
            this.loading = false;
        })
    }
  }
};
</script>
