<template>
  <div v-if="num>0" class="NotiBouble">
    <span>{{ num }}</span>
  </div>
</template>

<script>
export default {
  name: "bouble",
  props: {
    width: {
      default: 100
    },
    num: {
      default: 0
    }
  }

};
</script>
