<template>

  <div v-if="this.$store.state.auth.rol == 'asesor' || message.public || message.user_id == this.$store.state.auth.id" :class="this.clasesOfMessage()"  class="mt-1">
    <div v-if="!message.from_system && message.user" class="rounded-circle imageAvatar avatarChat" >

      <img v-if="message.user.profile_image == null" :src="this.$store.state.baseUrl+'/images/profiles/default.png'" alt="avatar">
      <img v-else :src="message.user.profile_image.url+'/100.'+message.user.profile_image.format" alt="avatar">
      
      <span v-if="this.isFromother()" class="ml-5">&nbsp;&nbsp; {{message.user.name}}</span>
    </div>
    <div  @click="markMessageAsRead()" v-if="!message.from_system" :class="{ 'message my-message': isFromother(), 'message other-message float-left': !isFromother(), ' public-message': message.public, 'no-readed': !isReaded()}">
      <readedBy v-if="this.auth.rol == 'asesor' || message.user_id == this.auth.id" :users="this.data.users" :message="message" />
      <p  v-html="message.message"></p>
      <div v-if="message.document_id" class="btn btn-link text-white">
        <router-link
          :to="{
            name: 'Archivo',
            params: {
              id: message.document_id
            }
          }">
          - Abrir
        </router-link>
      </div>
      

      <div class="text-xs horaMensaje">
        <Cargador class="position-absolute" v-if="loading" :width="30"/>
        {{this.$store.state.dateFormat(message.created_at)}}    
        <svg v-if="this.readByAlls()" xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-check-all" viewBox="0 0 16 16">
          <path d="M8.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L2.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093L8.95 4.992a.252.252 0 0 1 .02-.022zm-.92 5.14.92.92a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 1 0-1.091-1.028L9.477 9.417l-.485-.486-.943 1.179z"/>
        </svg>   
      </div>
      

      
      
    </div>
    <div v-if="message.from_system" class="systemMessage mt-3">
      Se ha abierto un 
      <router-link :to="{
      path: '/tickets/'+message.ticket_id
      }">
        Asunto ({{ message.ticket.title }})
    </router-link>
    <hr>
    <router-link :to="{
      path: '/chats/'+message.ticket.chat_id
      }">
        Ir al chat del asunto
      </router-link>
    
  
    </div>

  </div>


</template>

<script>

// import headerChat from "./headerChat.vue";
import readedBy from "@/views/components/Chats/readsBy.vue";
import Cargador from "@/components/Cargador.vue";
import axios  from 'axios';





export default {
  name: "currentChat",
  emits: ['new-message'],
  props: ['message','dataIn'],
  data() {
    return {
      auth:this.$store.state.auth,
      loading:false,
      data: null,
      error:false,
      readed_by:[],
      userIds: [this.$store.state.auth.id],
      form: {
        message:"",
        chat_id: this.$route.params.id

      }
    };
  },
  components: {
    Cargador,
    readedBy
  },
  // beforeRouteLeave (to, from, next) {
  //   // onMessage(messaging, (payload) => {
  //   //   this.notificate(payload)
  //   //   console.log('Mensaje recibido. ', payload);
  //   //   var audio = new Audio('/sound.wav');
  //   //   audio.play();
  //   // });
  //   next()
  // },
  created() {
    this.data = this.dataIn;
    this.readed_by = JSON.parse(this.message.readed_by)
    // this.getData();
      console.log("MENSAJE",this.message)
    
  },
  methods: {
    readByAlls() {
      return (this.data.users.length == this.readed_by.length)
    },
    markMessageAsRead(){
      if(JSON.parse(this.message.readed_by).includes(this.auth.id))
        return
      this.loading = true
      var self = this
      var token = this.$store.state.token
      axios.defaults.headers.common['Authorization'] = 'Bearer '+token
      axios.post(this.$store.state.baseUrl+'/api/message/'+this.message.id+'/read')
        .then(response => {
          if(response.data.rc == 1) {
            console.log(response)
            this.quitPendingMessageOfGeneral()
          }
        })
        .catch(function () {
            self.error = true
        })
        .finally(function () {
            self.loading = false
        })
    },
    quitPendingMessageOfGeneral() {
      for (let index = 0; index < this.$store.state.general.pendingMessages.length; index++) {
        if(this.$store.state.general.pendingMessages[index].id == this.message.id){
          this.$store.state.general.pendingMessages.splice(index, 1);
          this.quitPendingNotification();
        break; // Opcional: salir del bucle una vez que se ha eliminado el elemento
        }
        
        
      }
    },
    quitPendingNotification() {
      for (let index = 0; index < this.$store.state.general.notifications.length; index++) {
        if(this.$store.state.general.notifications[index].message_id == this.message.id){
          this.$store.state.general.notifications[index].readed = true
        break; // Opcional: salir del bucle una vez que se ha eliminado el elemento
        }
        
        
      }
    },
    // añade el mensaje como leído
    isFromother() {
      if(this.message.user)
        return this.message.user_id !== this.$store.state.auth.id;
      else
        return false
    },

    isReaded() {
      if(this.message.user_id == this.auth.id)
        return true
      
      return  JSON.parse(this.message.readed_by).includes(this.auth.id)
    },

    clasesOfMessage() {
      var clases = 'clearfix'
      if(!this.isFromother())
        clases+=' text-right'
      
      
     
      return clases
    }
   
  }
};
</script>
