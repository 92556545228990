/**
=========================================================
* Vue Soft UI Dashboard - v2.0.0
=========================================================

* Product Page: https://creative-tim.com/product/vue-soft-ui-dashboard
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";

// // Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getMessaging, getToken } from "firebase/messaging";
//
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries
//
// // Your web app's Firebase configuration
// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyCW7RnUid3HLivLFVPAlYK3ua7-qG4g03w",
//   authDomain: "innoe-test.firebaseapp.com",
//   projectId: "innoe-test",
//   storageBucket: "innoe-test.appspot.com",
//   messagingSenderId: "795867377926",
//   appId: "1:795867377926:web:96de605cb66c41541e2e87",
//   measurementId: "G-13GBE1PSJL"
// };
//
// // Initialize Firebase
// const appFirebase = initializeApp(firebaseConfig);
//
// const messaging = getMessaging(appFirebase);
// getToken(messaging, { vapidKey: 'BImbGlvJDXyr-5AOi19HHjNReoTbTjyMw9tiHaaGwpYOh9vAduHVCDRebVXjP1pL1eNvFFOd-2rLwEHvzS4NzRM' }).then((currentToken) => {
//   if (currentToken) {
//     // Send the token to your server and update the UI if necessary
//     console.log('TOKEN de dispositivo',currentToken)
//     // ...
//   } else {
//     // Show permission request UI
//     console.log('No registration token available. Request permission to generate one.');
//     // ...
//   }
// }).catch((err) => {
//   console.log('An error occurred while retrieving token. ', err);
//   // ...
// });




// Initialize Firebase Cloud Messaging and get a reference to the service
//--

import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import SoftUIDashboard from "./soft-ui-dashboard";
// import "bootstrap/dist/css/bootstrap.min.css"
// import "bootstrap"













const appInstance = createApp(App);
appInstance.use(store);
// appInstance.use(appFirebase);


// appInstance.use(BootstrapVue);

appInstance.use(router);
appInstance.use(SoftUIDashboard);
appInstance.mount("#app");
