<template>
  <div class="py-4 container-fluid">
    <div class="card">
    <Cargador v-if="loading" />
    <div v-else class="card-body">
      <div class="">
        <h5 class="font-weight-bolder">
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-people-fill" viewBox="0 0 16 16">
            <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7Zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm-5.784 6A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216ZM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"/>
          </svg>

          El equipo:</h5>

        <div class="contieneAsesoresScroll mt-2">
          <vsud-avatar
            v-for="asesor in asesores"
            :key="asesor.id"
            size="xl"
            border-radius="lg"
            alt="user1"
            :user="asesor"
            :circular="true"
          />
          <h6 v-if="asesores.length == 0">


            No tienes Asesores asignados

          </h6>


        </div>
      </div>
      <div class="mt-5">
        <h5 class="font-weight-bolder">Colaboradores:</h5>

        <div class="contieneAsesoresScroll mt-2">
          <h6>Todavía no hay colaboradores</h6>

        </div>
      </div>


    </div>
  </div>
  </div>
</template>

<script>
import VsudAvatar from "@/components/VsudAvatar.vue";

import Cargador from "@/components/Cargador.vue";
import axios  from 'axios';


export default {
  data() {
    return {
      loading:true,
      asesores: []
    };
  },
  name: "Despacho",
  components: {
    Cargador,
    VsudAvatar
  },
  mounted() {
    this.getTeam()
  },
  methods: {
    getTeam() {
      this.loading = true;
      var token = this.$store.state.token
      axios.defaults.headers.common['Authorization'] = 'Bearer '+token
      var self = this
      axios.get(this.$store.state.baseUrl+'/api/despacho', null)
        .then(response => {
          if(response.data.rc == 1) {
            self.asesores = response.data.data
          } else {
            this.error = true
          }
        })
        .catch(function () {
            self.error = true
        })
        .finally(function () {
            self.loading = false
        })
    }
  }

};
</script>
