<template>
  <div class="py-4 container-fluid">
    <div v-if="error" class="alert alert-danger m-3" role="alert">
      Parece que ha habido algún error
    </div>
    <Cargador v-if="loading"/>
    <div v-else class="row">
      <div class="col-md-12">
        <Migas :info="info.folder_road" />
      </div>
      <!-- Visor del documento -->
      <div class="col-md-8">
        <div class="embed-responsive">
          <!-- {{this.$store.state.baseUrl+'/storage/'+info.path}} -->
          <object class="embed-responsive-item" :data="info.aws_url" width= "100%" height= "700"></object>
        </div>

      </div>
      <!-- Otra info -->
      <div class="col-md-4">

        <div class="card">
          <div class="card-body">
            <h5 class="font-weight-bolder">Título: {{info.name}}</h5>
            <div class="mb-5">
              <p v-if="info.department !== null"><strong>Departamento: </strong> {{info.department.name}} <br></p>
              <p><strong>Descripción: </strong> {{info.description}}</p>
              <p><strong>Año: </strong> {{info.year}}</p>
              <p v-if="info.expiration_on !== null"><strong>Caducidad: </strong> {{info.expiration_on}}</p>
              <p v-if="info.alert_on !== null"><strong>Caducidad: </strong> {{info.expiration_on}}</p>
              <p v-else>Sin fecha de aviso</p>
              <div v-if="info.attributes!==null && info.attributes.length>0">
                <b>Atributos: </b>
                  <ul>
                    <li  v-for="attribute in info.attributes"  :key="attribute.id">
                      {{attribute.name}}
                    </li>
                  </ul>
              </div>

              <div v-if="info.from !== null" class="">
                <b>Publicado por: </b>
                <br>
                <vsud-avatar
                  size="xl"
                  border-radius="lg"
                  alt="user1"
                  :user="info.from"
                  :circular="true"
                />
              </div>

              <div v-if="info.company" class="">
                <b>Empresa: </b>
                <avatarCompany 
                  size="xl"
                  border-radius="lg"
                  alt="user1"
                  :company="info.company"
                  :circular="true"
                  />
                <br>
                
              </div>

              <div v-if="info.ticket !== null" class="mt-3">
                <router-link
                  :to="{
                    name: 'Ticket',
                    params: {
                      id: info.ticket.id
                    }
                  }">
                  <button class="btn btn-info" type="button" name="button">
                    Ir a Asunto Asociado <br> #000{{info.ticket.id}}
                  </button>
                </router-link>
              </div>
              <div class="w-100 text-center">
                  <button @click="download()" class="btn btn-link" type="button" name="button">Descargar
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download" viewBox="0 0 16 16">
                        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
                        <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
                    </svg>
                    <Cargador v-if="loadingDownload"/>
                  </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import Cargador from "@/components/Cargador.vue";
import axios  from 'axios';
import VsudAvatar from "@/components/VsudAvatar.vue";
import Migas  from "@/components/Migas.vue";
import AvatarCompany from "@/components/VsudAvatarCompany.vue";


export default {
  name: "dashboard-default",
  data() {
    return {
      info:{
      },
      loading:true,
      loadingDownload: false,
      error:false
    };
  },
  mounted(){
    this.getInfo()
  },
  components: {
    Cargador,
    VsudAvatar,
    Migas,
    AvatarCompany
  },
  methods: {
    getInfo() {
      var token = this.$store.state.token

      axios.defaults.headers.common['Authorization'] = 'Bearer '+token
      axios.get(this.$store.state.baseUrl+'/api/documents/'+this.$route.params.id, null)
        .then(response => {
          if(response.data.rc == 1) {
            this.info = response.data.data
            this.loading = false

          } else {
            this.error = true
          }
        })
        .catch(error => {
          this.error = true
          console.log(error)
        })
        .finally(

        )
    },
    download() {
      var token = this.$store.state.token
      if(this.loadingDownload)
        return
      this.loadingDownload = true;
      axios.defaults.headers.common['Authorization'] = 'Bearer '+token
      axios.get(this.$store.state.baseUrl+'/api/documents/'+this.$route.params.id+'/download', null)
        .then(response => {
          if(response.data.rc == 1) {
            window.open(response.data.data, '_blank').focus();
            // console.log(response.data.data,'URL')
            this.loadingDownload = false

          } else {
            this.error = true
          }
        })
        .catch(error => {
          this.error = true
          console.log(error)
        })
        .finally(

        )

    }
  }
};
</script>
