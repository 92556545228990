<template>
    <div>
      <div v-if="this.error" class="alert alert-danger m-3" role="alert">
        Parece que ha habido algún error
      </div>
      <div v-if="showFilters" class=" pb-0">
        <div class="container-fluid">
          <div class="row d-flex justify-content-between">

            <div class="col-md-6 d-flex">
              <div class="filter m-auto w-100">
                <span class="ml-5">Buscar:</span> <br>
                <div class="input-group m-auto">
                  <span class="input-group-text text-body">
                    <i class="fas fa-search" aria-hidden="true"></i>
                  </span>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Buscar"
                    v-model="search"
                    v-on:keyup.enter="updateDocus()"
                  />
                </div>
                <small>Enter para aplicar</small>


                <div v-if="this.owner_id !== null" class="custom-control custom-switch">
                  <input @change="updateDocus()" type="checkbox" v-model="allSystem" class="custom-control-input" id="allSystem">
                  <label class="custom-control-label"  for="allSystem">Ver todos los archivos</label>
                </div>
                <!-- Para fase 2  -->
                <!-- <div v-if="this.$store.state.auth.rol == 'asesor'" class="custom-control custom-switch">
                  <input @change="updateDocus()" type="checkbox" v-model="allSystem" class="custom-control-input" id="seeTags">
                  <label class="custom-control-label"  for="seeTags">Ver Atributos</label>
                </div> -->
                <div v-if="this.$store.state.auth.rol == 'asesor'" class="custom-control custom-switch">
                  <input @change="updateDocus()" type="checkbox" v-model="seePath" class="custom-control-input" id="customSwitch1">
                  <label class="custom-control-label"  for="customSwitch1">Ver Ruta de carpeta</label>
                </div>

                <div v-if="this.$store.state.auth.rol == 'asesor'" class="input-group mt-3">
                  <div class="d-flex flex-wrap">
                    <router-link
                    :to="{

                      path: '/upload',

                    }"
                    >

                    <button type="button"  class="btn btn-secondary m-1" name="button">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cloud-upload-fill" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M8 0a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 4.095 0 5.555 0 7.318 0 9.366 1.708 11 3.781 11H7.5V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11h4.188C14.502 11 16 9.57 16 7.773c0-1.636-1.242-2.969-2.834-3.194C12.923 1.999 10.69 0 8 0zm-.5 14.5V11h1v3.5a.5.5 0 0 1-1 0z"/>
                      </svg>
                      Subir Documento
                    </button>
                    </router-link>
                    <button v-if="this.$store.state.auth.rol == 'asesor'" type="button" @click="showUsersSearch = !showUsersSearch" class="btn btn-secondary mt-1" name="button">Filtrar por cliente</button>
                    <!-- <div v-if="this.search.length >0" class="custom-control custom-switch w-100">
                      <input v-model="keepFolders" type="checkbox" class="custom-control-input" id="keepFolders">
                      <label class="custom-control-label" for="keepFolders">Mantener Carpetas</label>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <span class="ml-5">Atributos:</span> <br>

                  <vSelect
                      v-model="attributes"
                      :reduce="attributes => attributes.id"
                      label="name"  multiple  :options="this.$store.state.general.attributes" v-on:option:selected="updateDocus()" v-on:keyup.delete="updateDocus()" v-on:option:deselected="updateDocus()" >
                  </vSelect>
            </div>

            <!-- <div class="col-md-3 ">
                <small class="ml-5">Año:</small> <br>
                <select @change="updateFilter()" class="form-select selector" >
                  <option select :value="null">Todos</option>
                  <option v-for="year in this.years"  :key="year" :value="year">{{year}}</option>
                </select>
            </div> -->


          </div>
          


          <div v-if="this.$store.state.auth.rol == 'asesor' && showUsersSearch" class="row">
            <searchUsers  :onlyClients="true" :to_id="owner_id"   v-on:update:to_id="owner_id = $event;this.updateDocus()"/>
          </div>

          <!-- <div class="row mt-3">
            <div class="col-md-12">
              <span class="ml-5">Departamentos:</span>
              <div class="d-flex flex-wrap">
                <button @click="changeDepartment(null)" class="btn btn-secondary m-1" type="button" name="button">Todos</button>
                <button
                  v-for="department in this.$store.state.general.departments"
                  :key="department.id"
                  class="btn btn-secondary m-1"
                  type="button"
                  @click="changeDepartment(department.id)"
                  :style="'background: '+department.color"
                  name="button">
                  {{department.name}}
                </button>
                <button @click="changeDepartment('ofTickets')" class="btn btn-secondary m-1 ml-4" type="button" name="button">De tickets</button>


              </div>

            </div>
          </div> -->

        </div>
      </div>
      <FilesFolders
      :filters="this.filters"
      :key="$route.fullPath+'#'+number"
      :showFolders="this.showFolders"
      :search="this.search"
      :owner_id="this.owner_id"
      :allSystem="this.allSystem"
      :seePath="this.seePath"
      :attributes="this.attributes"
      />


    </div>

</template>

<script>
// import axios  from 'axios';
import searchUsers from "@/components/SearchUsers.vue";
import FilesFolders from "@/views/components/Documentacion/filesAndDocus.vue";
// import Tags from '@voerro/vue-tagsinput';

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";




export default {
  name: "documents-table",
  data() {
    return {
      number:0,
      loading:true,
      documents: [],
      departments: [],
      subDepartments:[],
      selection: 0,
      filtro: null,
      error: false,
      years:[],
      search:"",
      showUsersSearch: false,
      owner_id: null,
      migas: [],
      attributes:[],
      keepFolders:false,
      allSystem: false,
      seePath: false


    };
  },
  props: {
    showFilters:{
      type: Boolean,
      default:false
    },
    showFolders: {
      type: Boolean,
      default:true
    },
    filters: {
      default: {
        ticket_id: null,
        year: null,
        department_id: null,
        onlyTickets: null,
        showDepartments:true,
        owner_id: null

      }

    }
  },
  components: {
    searchUsers,
    FilesFolders,
    // Tags,
    vSelect

    // VsudPagination,
    // VsudPaginationItem
  },
  mounted() {
    this.filtros = this.filters
    // this.$forceUpdate();



  },
  methods: {
    updateDocus() {
      this.number++;

    },


    changeDepartment(department) {
      if(department == 'ofTickets') {
        this.filtros.onlyTickets = true
        this.filtros.department_id = null;
        this.filtros.showDepartments = false
        this.getDocu()
        return true;
      }
      this.filtros.onlyTickets = null
      this.filtros.department_id = department
      if(department == null)
        this.filtros.showDepartments = true
      this.getDocu()
    },
    updateFilter() {
      if(this.selection == 0)
        this.filtros.status = null;
      if(this.selection == 1)
        this.filtros.status = "pending";
      if(this.selection == 2)
        this.filtros.status = "success";
      this.getDocu()
    },
    tagQuit(e) {
      var index = this.attributes.indexOf(e.value);
      if (index !== -1) {
        this.attributes.splice(index, 1);
      }

    },
    tagPlus(e) {
      this.attributes.push(e.value);
    }

  }
};
</script>
