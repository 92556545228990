<template>
  <div class="py-4 container-fluid">

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="Devolver">
              <div class="form-group">
                <h3 >Solicitar Reunión</h3>
                
                <form @submit.stop.prevent="save()">
                  <div class="row">
                  
                  <!-- Datos -->
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Título/Asunto de la reunión</label>
                      <input v-model="form.title" required type="text" class="form-control">
                    </div>
                    <div class="form-group">
                      <label>Ubicación</label>
                      <select v-model="form.ubication" required class="form-select" name="">
                        <option value="Despacho">Despacho</option>
                        <option value="Online">Online</option>
                        <option value="Otros">Otros</option>
                      </select>
                      <input v-model="form.ubication_datails" v-if="form.ubication =='Otros'" placeholder="Ubicación" type="text" class="form-control mt-3">
                    </div>
                    <div class="form-group">
                      <label>Url: </label>
                      <input v-model="form.url" type="text" class="form-control">
                    </div>
                    <div class="form-group">
                      <div class="form-check">
                        <input v-model="form.online" class="form-check-input" type="checkbox" value="" id="defaultCheck1">
                        <label  class="form-check-label" for="defaultCheck1">
                          Online (crea reunión en Teams)
                        </label>
                      </div>
                    </div>

                  </div>
                  
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Fecha: {{form.meet_on}} </label>
                      <input v-model="form.meet_on" type="date" required class="form-control">
                    </div>
                    <div class="form-group">
                      <label>Hora: </label>
                      <input required v-model="form.at" step="60" type="time" class="form-control">
                    </div>
                    <div class="form-group">
                      <label>Descripción/detalles</label>
                      <textarea v-model="form.description" required class="form-control" rows="3"></textarea>
                    </div>
                  </div>

                  <div v-if="this.form.ticket_id" class="col-md-12">
                    <router-link 
                    target="_blank"
                      :to="{
                        path:'/tickets/'+this.form.ticket_id,
                        }" >
                      <button type="button" class="btn btn-info">
                        Asunto asociado #00{{ this.form.ticket_id }}
                      </button>
                    </router-link>
                  </div>
                  
                  <div v-if="!this.form.ticket_id"  class="col-md-12 mt-4">
                    <searchUsers :to_id="form.company_id"  v-on:update:to_id="form.company_id = $event" v-on:update:users_ids="form.users_ids = $event" :title="'Usuarios de la reunión'" :users_ids="form.users_ids"/>
                  </div>
                  
                  <div v-if="!loading" class="col-md-12 mt-4">
                    <button class="btn btn-primary" type="submit" name="button">Solicitar</button>
                  </div>
                  <Cargador v-else />
                 
                </div>
                </form>
              </div>
            </div>
            <div v-if="error" class="alert alert-danger m-3" role="alert">
              Parece que ha habido algún error
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
<script>

import Cargador from "@/components/Cargador.vue";
import axios  from 'axios';
import searchUsers from "@/components/SearchUsers.vue";

// import VsudAvatar from "@/components/VsudAvatar.vue";

export default {
  name: "dashboard-default",
  data() {
    return {
      
      form: {
        title:"",
        ubication: "Despacho",
        description:"",
        url:"",
        ubication_datails:"",
        online:true,
        meet_on:null,
        at:null,
        users_ids: [],
        to_id:false,
        ticket_id:null,
        company_id: null,
        pivot: {
          status: ""
        }

      },
      loading:false,
      loadingConfirm:false,
      error:false,
      hasTicket: false
    };
  },
 
  created(){
    // si enviamos un id de ticket o un id de cliente que ya se añada
    
    // this.form.users_ids = this.users_ids
    if(this.$route.query.to_id)
      this.form.to_id = this.$route.query.to_id

    if(this.$route.query.ticket_id)
      this.form.ticket_id = this.$route.query.ticket_id
    
    
  },
  components: {
    Cargador,
    searchUsers,

    // VsudAvatar
  },
  methods: {
   save() {
      this.form.to_id = this.form.company_id
      this.loading = true;
      var token = this.$store.state.token
      axios.defaults.headers.common['Authorization'] = 'Bearer '+token
      axios.post(this.$store.state.baseUrl+'/api/meet/create', this.form)
        .then(response => {
          if(response.data.rc == 1) {
            // window.location.href = ;
            this.$router.push({path: '/meets/'+response.data.data.id })


          } else {
            this.error = true
          }
        })
        .catch(error => {
          this.error = true
          console.log(error)
        })
        .finally(
          this.loading = false
        )
    },
   
    
  }
};
</script>
