<template>
  <div class="mt-3 p-2">
    <div v-for="chat in this.groupedChats()" :key="chat.id" class="accordion-item">
      <div>
        <router-link 
        class="p-2 d-flex w-100 justify-content-between"
            :to="{
              path: '/chats/'+chat.id
            }" >
              {{ chat.ticket.title }}
              <!-- <span v-if="hasPending(chat)" class="pendingMessageBoubleChatListTicket"></span> -->
              <!-- <small class="text-xs">{{this.$store.state.dateFormat(chat.last_message[0].created_at)}}</small> -->
              <bouble :num="countPeningsByAsunto(chat)"/>
          </router-link>
          

          <!-- <div v-if="chat.last_message[0]" class="d-flex w-100 justify-content-between">
                <small>{{chat.last_message[0].message}}</small>
                <small class="text-xs">{{this.$store.state.dateFormat(chat.last_message[0].created_at)}}</small>

            </div> -->
        <hr>
      </div>
      
    </div>
  </div>
</template>

<script>
import _  from 'lodash';
import bouble from "@/components/bouble.vue"



export default {
  name: "all",
  data() {
    return {
      groupedData: [],
      counter: 1
    };
  },
  computed: {
    incrementedCounter() {
      return () => this.counter++;
    }
  },
  components:{
    bouble
  },
  methods: {
    getCounter() {
      return this.counter++;
      
    },


    countPeningsByAsunto(chat) {
      console.log('chat',chat)
      const f = _.filter(this.$store.state.general.pendingMessages, { chat_id: chat.id });  
      return _.size(f);  
    },


    getNameChat(chat){
      // const rol = this.$store.state.auth.rol
      // nombre ticket
      if(chat.ticket)
        return "Asunto - "+chat.ticket.title
      // si es asesor y hay empresa empresa
      // if(chat.company && rol == 'asesor')
      //   return chat.company.name
      return "General"
    },
    // el nombre en header
    getGeneralName(groupedChats) {
      // recogemos el primer chat
      const firstChat = groupedChats[0];
      // rol del user 
      const rol = this.$store.state.auth.rol
      // si tiene empresa y es asesor se verá el nombre de la empresa
      if(rol == 'asesor' && firstChat.company)
        return firstChat.company.name
      // si el chat tiene ticket sin empresa
      if(firstChat.ticket && firstChat.company == null)
        return "Interno - Asesores"
      // si es despacho se mostrará el nombre despacho
      if(rol == 'asesor' && firstChat.despacho)
        return "Despacho"
      // finalmente, independientemente del rol. Se mostrará el nombre 
      var name = "Desconocido"
      firstChat.users.forEach(user => {
        if(user.id !== this.$store.state.auth.id)
          name = user.name
      });
      return name;
      
    },
    hasPending(chat) {
      if(chat.last_message == null)
        return false;
      if(chat.last_message.length == 0)
        return false;
      var n = JSON.parse(chat.last_message[0].readed_by);
      return !n.includes(this.$store.state.auth.id);
    },
    countPendingsChats(groupedChats) {
      console.log('cuntingPendings',groupedChats)
      var count = 0;
      groupedChats.forEach(chat => {
        if(this.hasPending(chat))
          count++;
      });
      return count;
    },
    // group chats
    groupedChats() {
      // this.groupedData = _.groupBy(this.$store.state.chats.list, 'id');
      var chats = this.$store.state.chats.list;
      chats = _.filter(chats, (chat) => {
        return chat.ticket !== null;
      });
      return chats;
    },
    // ordenamos con tal de que los chats agrupados quede el "chat general" primero
    chatsFirstGeneral(grouped) {
      console.log('grouped',grouped)
      const sortedData = _.orderBy(grouped, ['ticket'], ['desc']);

      return sortedData
      
        
      
    }
  }

  
  
};
</script>
