<template>
   <div>
    <div v-if="showFilters" class=" pb-0">
      <div class="container-fluid">
          <div class="row d-flex justify-content-between">

            <!-- <div class="col-md-6">
              <div class="filter m-auto w-100">
                <button type="button" class="btn btn-secondary" name="button">Crear Reunión</button>
              </div>
            </div> -->

            <div class="col-md-3 d-flex ">
              <!-- FILTRO -->
                <small class="ml-5">Filtrar:</small> <br>
                  <select v-model="selection" class="form-select selector" >
                    <option value="all">Próximas Reuniones</option>
                    <option value="pending">Reuniones pasadas</option>
                  </select>
            </div>

          </div>

        </div>
    </div>

  <div class="table-responsive mt-3 p-0 col-12">
    <Cargador v-if="this.loading"/>
    <div v-else-if="filterMeets().length == 0" class="text-center w-100">
      <img width="150" src="not-found.gif" alt="">
      <h6  class="m-3">No se han encontrado reuniones</h6>
    </div>
    <table v-else class="table align-items-center mb-0">
      <thead>
        <tr>
          <th
            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 d-none d-md-table-cell"
          >
            Motivo
          </th>
          <th
            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2 "
          >
            Fecha
          </th>
          <th
          class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2 d-none d-md-table-cell"
          >
            Motivo
          </th>
          <th
          class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2 d-none d-md-table-cell"
          >
            Lugar
          </th>
          <th
            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
          >
            Asesores
          </th>
          <th
            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
          >
            Cliente
          </th>
          <th
            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
          >
            Opciones
          </th>
        </tr>
      </thead>

      <tbody class="mt-2">
        <h4 class="m-3" v-if="nextMeets.length == 0">No hay Reuniones</h4>
        <tr v-for="meet in nextMeets"  :key="meet.id">
          <td class="d-none d-md-table-cell">
            <div v-if="meet.status == 'confirmed'" class=" ">
              <i class="fa fa-circle me-1 m-1 success"></i> {{meet.title}}
            </div>
            <div v-if="meet.status == 'pending'" class=" ">
              <i class="fa fa-circle me-1 m-1 pending"></i> {{meet.title}}
            </div>
            <div v-if="meet.status == 'refused'" class="">
              <i class="fa fa-circle me-1 m-1 warning"></i> {{meet.title}}
            </div>
            

          </td>
          <td>
            <div class="d-flex ">
              <span class="text-xs">{{meet.meet_on}} <br>{{meet.at}} </span>
            </div>
          </td>
          <td class=" d-none d-md-table-cell">
            <div class="d-flex ">
              <span>{{meet.title}}</span>
            </div>
          </td>

          <td class=" d-none d-md-table-cell">
            <div class="d-flex ">
              <span>{{meet.ubication}}</span>
            </div>
          </td>

          <td class=" d-none d-md-table-cell">
            <div class="d-flex ">
              <ul>
                <li v-for="asesor in meet.asesores"  :key="asesor.id">
                  <router-link
                    :to="{
                      name: 'User',
                      params: {
                        id: asesor.id
                      }
                    }"

                  >
                  {{asesor.name}}
                  </router-link>
                </li>
              </ul>

            </div>
          </td>
          <td class=" d-none d-md-table-cell">
            <div class="d-flex ">
              <span v-if="meet.company"> {{meet.company.name}}</span>
              <!-- Si hay usuarios de tipo clientes -->
              <div v-if="meet.clients.length > 0">
                &nbsp;
                <span v-for="client in meet.clients"  :key="client.id">{{client.name}} {{client.surnames}}, &nbsp;</span>
              </div>
            </div>
          </td>


          <td>
            <div class="d-flex ">

              <router-link
                :to="{
                  name: 'Reunión',
                  params: {
                    id: meet.id
                  }
                }"

              >
                <vsud-badge class="m-1" color="success" variant="gradient" size="m">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-right-square" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm5.854 8.803a.5.5 0 1 1-.708-.707L9.243 6H6.475a.5.5 0 1 1 0-1h3.975a.5.5 0 0 1 .5.5v3.975a.5.5 0 1 1-1 0V6.707l-4.096 4.096z"/>
                  </svg>
                </vsud-badge>
            </router-link>
            <vsud-badge v-if="this.$store.state.auth.rol !== 'client'" @click="deleteMeet(meet)" class="m-1 pointer" color="danger" variant="gradient" size="m">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                  <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                </svg>
            </vsud-badge>

            </div>
          </td>


        </tr>
        <!-- <tr>
          <td class="d-none d-md-table-cell">
            <div class="d-flex ">
              <i class="fa fa-circle me-1 m-1 success"></i>Resuleto
            </div>
          </td>
          <td>
            <div class="d-flex ">
              <span>17/02/2022 <br>17:30 </span>
            </div>
          </td>
          <td class=" d-none d-md-table-cell">
            <div class="d-flex ">
              <span class="text-xs">Puesta en marcha de cliente</span>
            </div>
          </td>

          <td class=" d-none d-md-table-cell">
            <div class="d-flex ">
              <span class="text-xs">Online</span>
            </div>
          </td>

          <td class=" d-none d-md-table-cell">
            <div class="d-flex ">
              <span class="text-xs">Juan, Carmen</span>
            </div>
          </td>


          <td>
            <div class="d-flex ">
              <vsud-badge class="m-1 flex" color="success" variant="gradient" size="m">
                <span>Abrir Enlace</span>
              </vsud-badge>
              <vsud-badge class="m-1" color="success" variant="gradient" size="m">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-right-square" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm5.854 8.803a.5.5 0 1 1-.708-.707L9.243 6H6.475a.5.5 0 1 1 0-1h3.975a.5.5 0 0 1 .5.5v3.975a.5.5 0 1 1-1 0V6.707l-4.096 4.096z"/>
                </svg>
              </vsud-badge>

            </div>
          </td>


        </tr> -->



      </tbody>
    </table>

  </div>
   </div>
</template>

<script>
import VsudBadge from "@/components/VsudBadge.vue";
import axios  from 'axios';
import Cargador from "@/components/Cargador.vue";

export default {
  name: "tickets-table",
  data() {
    return {
      loading:true,
      nextMeets:[],
      selection: "all"
    };
  },
  props: {
    showFilters:{
      type: Boolean,
      default:false
    }
  },
  components: {
    VsudBadge,
    Cargador
    // VsudPagination,
    // VsudPaginationItem
  },
  mounted(){
    this.getNextMeets();
  },
  methods: {
    deleteMeet(meet) {
      if(!confirm("¿Estás seguro que deseas eliminar la reunión?"))
        return;
      var token = this.$store.state.token

      axios.defaults.headers.common['Authorization'] = 'Bearer '+token
      var self = this
      axios.post(this.$store.state.baseUrl+'/api/meets/'+meet.id+'/delete', null)
        .then(response => {
          if(response.data.rc == 1) {
            this.getNextMeets()
          }
        })
        .catch(function () {
            self.error = true
        })
        .finally(
          this.loading = false
        )
    },
    getNextMeets() {
      this.loading = true;
      var token = this.$store.state.token

      axios.defaults.headers.common['Authorization'] = 'Bearer '+token
      var self = this
      axios.post(this.$store.state.baseUrl+'/api/meets', {
        company_id: this.$store.state.currentCompany_id
      })
      .then(response => {
        if(response.data.rc == 1) {
          self.nextMeets = response.data.data
        } else {
          this.error = true
        }
      })
      .catch(function () {
          self.error = true
      })
      .finally(function () {
          self.loading = false
      })
    },
    filterMeets() {
      return this.nextMeets
      // return this.nextMeets.filter(ticket => {
      //   console.log(ticket)
      //   if(this.selection == 'all')
      //    return true
      //   if(this.selection == 'pending')
      //     return (ticket.status == 'pending' || ticket.status == 'error' || ticket.status == 're-open')
      //   if(this.selection == 'closed')
      //     return ticket.status == 'done'
      // })
    },  
  }
};
</script>
