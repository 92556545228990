<template>
  <div class="py-4 container-fluid mt-3">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header pb-0">
            <router-link
            v-if="this.$store.state.auth.rol == 'asesor'"
            :to="{
              name: 'Abrir Asunto',

            }"
            >
              <button type="button" class="btn btn-secondary" name="button">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-ticket-detailed-fill me-1" viewBox="0 0 16 16">
              <path d="M0 4.5A1.5 1.5 0 0 1 1.5 3h13A1.5 1.5 0 0 1 16 4.5V6a.5.5 0 0 1-.5.5 1.5 1.5 0 0 0 0 3 .5.5 0 0 1 .5.5v1.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 11.5V10a.5.5 0 0 1 .5-.5 1.5 1.5 0 1 0 0-3A.5.5 0 0 1 0 6V4.5Zm4 1a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 0-1h-7a.5.5 0 0 0-.5.5Zm0 5a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 0-1h-7a.5.5 0 0 0-.5.5ZM4 8a1 1 0 0 0 1 1h6a1 1 0 1 0 0-2H5a1 1 0 0 0-1 1Z"/>
            </svg>
                Abrir Asunto
                
              </button>
            </router-link>



          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-8">
                <h5 class="font-weight-bolder">Asuntos/Incidencias:</h5>
              </div>
            </div>
            <ticketsTable :showFilters="true" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

// import VsudPagination from "@/components/VsudPagination.vue"
import ticketsTable from "@/views/components/Tickets/table.vue"

export default {
  name: "tickets",
  data() {
    return {

    };
  },
  components: {
    ticketsTable,
    // VsudPaginationItem
  },
};
</script>
