<template>
  <div>
  


    <div v-for="(chatGrouped,index) in this.groupedChats()" :key="'a'+index" class="accordion-item">
      <!-- header -->
        <div class="accordion-header p-3"  data-bs-toggle="collapse" :data-bs-target="'#collapse-'+chatGrouped[0].id" aria-expanded="false" aria-controls="collapseExample">
          <div class="d-flex w-100 justify-content-between">
            {{ this.getGeneralName(chatGrouped,index) }}
            <bouble v-if="count = this.countPendingsOfGroup(chatGrouped)" :num="count" class="pendingMessageBoubleChatListGeneral"></bouble>

            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle mt-auto mb-auto" viewBox="0 0 16 16">
              <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
            </svg>
          </div>
          
        </div>
        <!-- content -->
      <div class="collapse accordion-collapse p-3" :id="'collapse-'+chatGrouped[0].id">
        <div class="">
          <ul class="list-group">
            <div>
              <router-link   
                
                class="list-group-item"
                v-for="chat in this.chatsFirstGeneral(chatGrouped)"  
                :key="chat.id" 
                :to="{
                  name: 'Chat',
                  params: {
                    id: chat.id
                  }
                  
                }">
                <div class="d-flex w-100 justify-content-between">
                    <span class="mt-1">
                      {{this.getNameChat(chat)}} 
                      <span v-if="chat.pending?? false" class="bouble mt-3"></span>
                    </span>
                    <!-- <small class="text-xs">{{this.$store.state.dateFormat(chat.updated_at)}}</small> -->
                    <bouble :num="countPendings(chat)"/>
                    <!-- <span v-if="hasPending(chat)" class="pendingMessageBoubleChatList"></span> -->
                    

                </div>
                <!-- <hr> -->
                <div v-if="chat.last_message[0]" class="d-flex w-100 justify-content-between">
                  <!-- <small>{{chat.last_message[0].message}}</small> -->
                  <small class="text-xs">{{this.$store.state.dateFormat(chat.last_message[0].created_at)}}</small>
                </div>
                

              </router-link >
            </div>

            
            
          </ul>
        </div>
      </div>
      <hr>
    </div>
  </div>
</template>

<script>
import _  from 'lodash';
import bouble from "@/components/bouble.vue"


export default {
  name: "all",
  data() {
    return {
      groupedData: [],
      counter: 1
    };
  },
  computed: {
    incrementedCounter() {
      return () => this.counter++;
    }
  },
  components:{
    bouble
  },
  methods: {
    getCounter() {
      return this.counter++;
      
    },
    quitPending(chat) {
      if (chat.last_message && chat.last_message.length > 0) {
        var n = JSON.parse(chat.last_message[0].readed_by);
        n.push(this.$store.state.auth.id)
        chat.last_message[0].readed_by = JSON.stringify(n);
        this.$store.state.general.pendingMessages--;
        if(this.$store.state.general.pendingMessages<0)
          this.$store.state.general.pendingMessages=0
      }
    },


    getNameChat(chat){
      // const rol = this.$store.state.auth.rol
      // nombre ticket
      if(chat.ticket)
        return "Asunto - "+chat.ticket.title
      // si es asesor y hay empresa empresa
      // if(chat.company && rol == 'asesor')
      //   return chat.company.name
      return "General"
    },
    // el nombre en header
    getGeneralName(groupedChats) {
      // recogemos el primer chat
      const firstChat = groupedChats[0];
      // rol del user 
      const rol = this.$store.state.auth.rol
      // si tiene empresa y es asesor se verá el nombre de la empresa
      if(rol == 'asesor' && firstChat.company)
        return firstChat.company.name
      // si el chat tiene ticket sin empresa
      if(firstChat.ticket && firstChat.company == null)
        return "Asuntos Internos"
      // si es despacho se mostrará el nombre despacho
      if(rol == 'asesor' && firstChat.despacho)
        return "Despacho"
      // finalmente, independientemente del rol. Se mostrará el nombre 
      var name = "Desconocido"
      firstChat.users.forEach(user => {
        if(user.id !== this.$store.state.auth.id)
          name = user.name
      });
      return name;
      
    },
    // contar a partir de un chat
     countPendings(chat) {
      const f = _.filter(this.$store.state.general.pendingMessages, { chat_id: chat.id });  
      return _.size(f);  

    },
    //
    countPendingsOfGroup(group) {
      const chatIDs = _.map(group, 'id');
      const mensajesConMismoChatID = _.filter(this.$store.state.general.pendingMessages, (mensaje) => _.includes(chatIDs, mensaje.chat_id));
      console.log(mensajesConMismoChatID);
      return _.size(mensajesConMismoChatID);

    },
    // FUera prionto
    hasPending(chat) {
      if(chat.last_message == null)
        return false;
      if(chat.last_message.length == 0)
        return false;
      var n = JSON.parse(chat.last_message[0].readed_by);
      return !n.includes(this.$store.state.auth.id);
    },
        // FUera prionto

    countPendingsChats(groupedChats) {
      console.log('cuntingPendings',groupedChats)
      var count = 0;
      groupedChats.forEach(chat => {
        if(this.hasPending(chat))
          count++;
      });
      return count;
    },
    // group chats
    groupedChats() {
      // this.groupedData = _.groupBy(this.$store.state.chats.list, 'id');
      const chats = this.$store.state.chats.list;
      const groupedData = _.groupBy(chats, chat => {
        if(this.$store.state.auth.rol == 'asesor') {
          // si tiene ticket de empresa se agrupa por empresa
          if(chat.ticket && chat.company)
            return JSON.stringify(chat.company)
          // si tiene ticket sin empresa lo agrupamos por Equipo
          if(chat.ticket && chat.company == null)
            return "Interno"
          // Si Es empresa se agrupa por empresa
          if (chat.company) {
            return JSON.stringify(chat.company)
          } 
          if (chat.despacho) {
            return 'despacho';
          }
           else {
            var userOut = false
            chat.users.forEach(user => {
              if(user.id !== this.$store.state.auth.id)
                userOut = user 
            });
            
            if(userOut)
              return userOut.id
            else
              return "1"

          }
        } 
        //  si es cliente
        else {
          // si tiene ticket por el asesor que lo lleva
          if(chat.ticket) {
            var asesorOut = "Desconocido"
            chat.ticket.asesores.forEach(asesor => {
              if(asesor.pivot.admin)
              asesorOut = asesor 
            });
            return asesorOut.id
          }
            
          // sino por sus usuarios
          else {
            var idOut
            chat.users.forEach(user => {
              if(user.id !== this.$store.state.auth.id){
                idOut = user.id
              }
            });
            return idOut
          }
            
          
        }
      }); 
      return groupedData
    },
    // ordenamos con tal de que los chats agrupados quede el "chat general" primero
    chatsFirstGeneral(grouped) {
      console.log('grouped',grouped)
      const sortedData = _.orderBy(grouped, ['ticket'], ['desc']);

      return sortedData
      
        
      
    }
  }

  
  
};
</script>
