<template>
  <div class="col-12 mt-4">
    <div class="card">
      <div class="card-body">
        <form @submit.stop.prevent="addComment()" class="form-group">

          <label for="exampleFormControlTextarea1">Escribir Comentario</label>
          <textarea required :disabled="this.loading" v-on:keyup.enter="addComment()" v-model="form.content" class="form-control" rows="3"></textarea>
          <div class="mt-4 d-flex flex-wrap">
            <button type="submit" class="btn btn-primary m-1" name="button">Enviar</button>
            <button type="button" class="btn btn-secondary m-1" @click="this.addFile = !this.addFile" name="button">Añadir Documento</button>
            <button v-if="this.$store.state.auth.rol !== 'client'" type="button" class="btn btn-secondary m-1" @click="this.addForm = !this.addForm " name="button">Añadir Formulario</button>
            <Cargador class="m-2" v-if="loading" />
          </div>
          <!-- Añadir Form -->
          <div v-if="addForm" class="form-group">
            <label for="">Adjuntar formulario</label>
            <select required v-model="form.form_id" class="form-select">
              <option :value="0">Ninguno</option>
              <optgroup v-for="(department,index) in this.$store.state.general.forms"  :key="department.id" :label="index">
                <option  v-for="form in this.$store.state.general.forms[index]"  :key="form.id" :value="form.id">{{form.title}}</option>
              </optgroup>
            </select>
          </div>


          <!-- Añadir Doc -->
          <div v-if="addFile" class="form-group row">
            <div class="col-md-6">
              <label for="exampleFormControlFile1">Archivo</label>
              <input
                required
                type="file"
                ref="fileInput"
                class="form-control"
                @change="handleFileUpload( $event )"
              />
            </div>
            <div class="col-md-6">
              <label for="">Nombre Documento</label>
              <input v-model="form.name" required type="text" class="form-control"  placeholder="Nombre Documento">

            </div>


          </div>
        </form>
      </div>
    </div>
    <div v-if="error" class="alert alert-danger mt-2">
      <span>Vaya, parece que ha habido algún error</span>
    </div>
  </div>
</template>

<script>
import Cargador from "@/components/Cargador.vue";

import axios  from 'axios';

export default {
  name: "add-comment",
  emits: [
    "update:comentarios",
    "update:dataShowAddNewComment"
  ],
  data() {
    return {
      loading:false,
      addFile:false,
      addForm: false,
      form: {
        content:"",
        form_id: 0,
        file:null,
        name: null

      },

      error:false
    };
  },
  props: ['comentarios','parent_comment','dataShowAddNewComment'],
  components: {
    Cargador,

    // VsudAvatar,
    // Comments

    // VsudPagination,
    // VsudPaginationItem
  },

  methods :{
    handleFileUpload( event ){
      this.form.file = event.target.files[0];
      if( this.form.name == null || this.form.name == "") {
        var path = this.form.file.name
        this.form.name = path.split(/[\\/]/).pop().replace(/\.[^/.]+$/, '')

      }
    },
    addComment() {
      if(this.loading)
        return true;
      if(this.form.content.length == 0) {
        this.error = true;
        return true
      }

      this.loading = true;
      let formData = new FormData();
      if(this.form.form_id !== 0)
        formData.append('form_id', this.form.form_id);
      if(this.form.file !== null)
        formData.append('file', this.form.file);
      if(this.parent_comment !== null)
        formData.append('subcomment_id', this.parent_comment);
      formData.append('name', this.form.name);
      formData.append('content', this.form.content);
      formData.append('ticket_id', this.$route.params.id);

      this.error = false;
      //
      var token = this.$store.state.token
      axios.defaults.headers.common['Authorization'] = 'Bearer '+token
      axios.defaults.headers.common['Content-Type'] = 'multipart/form-data'
      axios.post(this.$store.state.baseUrl+'/api/tickets/'+this.$route.params.id+'/comment',formData)
        .then(response => {
          if(response.data.rc == 1) {
            var c = this.comentarios
            c.unshift(response.data.data)
            this.$emit('update:comentarios', c)
            this.$emit('update:dataShowAddNewComment', false)
            this.form = {
              content:"",
              form_id: 0,
              file:null
            }

            // window.scrollTo(0,)

          } else {
            this.error = true
          }
        })
        .catch(() =>  {
          this.error = true;
        })
        .finally(() =>  {
            this.loading = false
            // this.error = false
            window.scrollTo(0, window.scrollY+250);
        })
    }
  }


};
</script>
