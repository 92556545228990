<template>
  <div class="py-4 container-fluid">

    <Cargador v-if="this.loading"/>
    <div v-else-if="!error" class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <h3> {{ info.title }} </h3>
            <!-- <small>#10291021</small> -->
            <div class="row">
              <div class="col-md-6">
                <div class="mt-2">
                  <strong>Ubicación: </strong> <text> {{ info.ubication }}</text>
                </div>
                <div class="mt-2" v-if="info.ubication_datails">
                  <strong>Detalle Ubicación: </strong> <text>{{ info.ubication_datails }}</text>
                </div>
                <div class="mt-2">
                  <strong>Fecha: </strong> <text>{{info.meet_on}} <br> a las {{info.at}}</text>
                </div>
                <div v-if="info.ticket" class="mt-3">
                  <strong>Asunto</strong>: {{ info.ticket.title }}
                  <br>
                  <router-link 
                      :to="{
                        path:'/tickets/'+this.info.ticket_id,
                        }" >
                      <button class="btn btn-info">
                        Asunto asociado #00{{ this.info.ticket_id }}
                      </button>
                    </router-link>
                </div>
                <div class="mt-2">
                  <strong>Descripción/detalles:</strong>
                  <div class="form-group m-1  mt-2">
                    <textarea v-model="info.description" disabled class="form-control" rows="3"></textarea>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <label>Empresa: </label>

                <AvatarCompany
                  v-if="info.company"
                  size="xl"
                  border-radius="lg"
                  alt="user1"
                  :span="'Empresa'"
                  :company="info.company"
                  :circular="true"
                  class="m-auto"
                />
              </div>

              <div class="col-md-12">
                <hr>

                <label>Asesores: </label>
                <div class="contieneAsesoresScroll">
                  <vsud-avatar
                  v-for="asesor in info.users"
                  :key="asesor.id"
                  size="xl"
                  border-radius="lg"
                  alt="user1"
                  :span="isAdmin(asesor.pivot)"
                  :user="asesor"
                  :circular="true"
                />
                </div>
                
              </div>
               
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="error" class="alert alert-danger">
      <strong>Vaya</strong> parace que ha habido algun erorr. 
    </div>
    
  </div>
</template>
<script>

import Cargador from "@/components/Cargador.vue";
import axios  from 'axios';
import VsudAvatar from "@/components/VsudAvatar.vue";
import AvatarCompany from "@/components/VsudAvatarCompany.vue";


export default {
  name: "dashboard-default",
  data() {
    return {
      info:{
      },
      loading: true,
      error: false
    }
  },
  created(){
    this.load();
  },
  components: {
    Cargador,
    AvatarCompany,
    VsudAvatar

    // VsudAvatar
  },
  methods: {
    load() {
      this.loading = true;
      var token = this.$store.state.token
      axios.defaults.headers.common['Authorization'] = 'Bearer '+token
      axios.post(this.$store.state.baseUrl+'/api/meet/'+this.$route.params.id,{
      }).then(response => {
          if(response.data.rc == 1) {
            this.info = response.data.data
            

          } else {
            // this.error = true
          }
        })
        .catch(() => {
          this.error = true
        })
        .finally(() =>  {
            this.loading = false
        })
    },
    isAdmin(pivot) {
      if(pivot.admin)
        return "admin"
      return false;
    },

  
  }
};
</script>
