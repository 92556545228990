<template>
  <div  id="plist" class="people-list">
    
      <div  class="input-group">
          <div class="input-group-prepend">
              <span class="input-group-text"><i class="fa fa-search"></i></span>
          </div>
          <input type="text" class="form-control" @input="filterChatSearch()" v-model="this.$store.state.chats.form.search" placeholder="Buscar...">
          <button v-if="false" @click="this.showFilter()" class="btn btn-secondary">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-filter-circle-fill" viewBox="0 0 16 16">
              <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zM3.5 5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1 0-1zM5 8.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm2 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5z"/>
            </svg>
          </button>
      </div>
      <Cargador v-if="loading"/>
      <ul v-if="!loading && !error" class="list-unstyled chat-list mt-3 mb-0">
          <ul v-if="filter" class="nav nav-pills nav-fill mt-2 mb-2 " style="flex-wrap: nowrap;" role="tablist">
            <li class="nav-item text-sm">
              <a
                class="nav-link mb-0 px-0 py-1"
                data-bs-toggle="tab"
                role="tab"
                v-bind:class="{ 'selectedNAvs': this.form.filter == 'default' }"

                @click="filterType('default')"
                >Todos</a
              >
            </li>
            <li v-if="this.$store.state.auth.rol == 'asesor'" class="nav-item text-sm">
              <a
                class="nav-link mb-0 px-0 py-1 active "
                v-bind:class="{ 'selectedNAvs': this.form.filter == 'clients' }"
                data-bs-toggle="tab"
                role="tab"
                
                @click="filterType('clients')"
                >Empresas</a
              >
            </li>
            <li  class="nav-item text-sm">
              <a
                class="nav-link mb-0 px-0 py-1 active"
                v-bind:class="{ 'selectedNAvs': this.form.filter == 'tickets' }"
                data-bs-toggle="tab"
                role="tab"
                @click="filterType('tickets')"
                >Asuntos</a
              >
            </li>
            <li class="nav-item text-sm">
              <a
                class="nav-link mb-0 px-0 py-1"
                data-bs-toggle="tab"
                role="tab"
                v-bind:class="{ 'selectedNAvs': this.form.filter == 'asesores' }"

                @click="filterType('asesores')"
                >Equipo</a
              >
            </li>
            
         </ul >
      </ul>


      <div class="mt-3" v-if="JSON.stringify(this.chatsFiltered()) === '{}' && !this.loading && !this.error">
        <h6>Parece que no tienes chats disponibles</h6>
        <img width="150" class="chatnotFounds" src="/not-found.gif" alt="">
      </div>

      <div v-if="!loading && !error">
        <allChats v-if="this.form.filter == 'default'"/>
        <companiesChats v-if="this.form.filter == 'clients'"/>
        <ticketsChats v-if="this.form.filter == 'tickets'"/>
        <teamChats :key="this.$store.state.chats.list" v-if="this.form.filter == 'asesores'"/>

      </div>
      
      
      

      <div v-if="!loading && error">
        <div v-if="this.$store.state.auth.rol == 'client' && this.$store.state.currentCompany_id == null"  class="alert alert-warning">
            <h4>Debes Seleccionar una empresa</h4>
        </div>
        <div v-else class="alert alert-danger">
          <h4>Parece que ha habido un problema</h4>
        </div>
            
      </div>
  </div>

</template>

<script>

import Cargador from "@/components/Cargador.vue";
import allChats from "@/views/components/Chats/List/all.vue";
import companiesChats from "@/views/components/Chats/List/companies.vue";
import ticketsChats from "@/views/components/Chats/List/tickets.vue";
import teamChats from "@/views/components/Chats/List/team.vue";

import _  from 'lodash';

import axios  from 'axios';


export default {
  name: "chatList",
  data() {
    return {
      loading:true,
      chats:[],
      error: false,
      filter: true,
      key:0,
      form: {
        filter: "default", // clients, asesores, tickets
        search: ""

      }
    };
  },
  components: {
    Cargador,
    allChats,
    companiesChats,
    ticketsChats,
    teamChats
  },
  
  mounted() {
    if(this.$store.state.general.companies.length >1 && this.$store.state.currentCompany_id == null) {
      this.$store.state.errores.push({
        title: "Debes Seleccionar una empresa",
        desc: ""
      })
    }
     
    this.loadData()
    this.connectToSocket()
  },
  methods: {
    loadData(){
      var token = this.$store.state.token
      axios.defaults.headers.common['Authorization'] = 'Bearer '+token
      axios.post(this.$store.state.baseUrl+'/api/chats', {
        company_id: this.$store.state.currentCompany_id
      })
        .then(response => {
          if(response.data.rc == 1) {

            this.chats = this.$store.state.chats.list = response.data.data
            this.loading = false
            
            if(this.chats.length && this.$route.name == 'Chats') 
              this.$router.push({path: '/chats/'+this.chats[0].id })
            // this.processReaded();

          }
          // if(response.data.rc == 405) {
          //   this.mustSelect = true
          // }
        })
        .catch(error => {
          this.error = true
          console.log(error)
        })
        .finally(() => {
        });
    },
    // apply the search to store
    filterChatSearch() {
      var c = this.chats;
      // buscador
      if (this.$store.state.chats.form.search.length > 0) {
        this.$store.state.chats.list = c.filter(chat => {
          const search = this.$store.state.chats.form.search.toLowerCase();
          // por usuarios
          const users = chat.users.filter(user => {
            const name = user.name ? user.name.toLowerCase() : '';
            const email = user.email ? user.email.toLowerCase() : '';
            return name.includes(search) || email.includes(search);
          });
          // por company
          const company = chat.company && chat.company.name ? chat.company.name.toLowerCase() : '';
          // por ticket
          const ticket = chat.ticket && chat.ticket.title ? chat.ticket.title.toLowerCase() : '';
          return users.length > 0 || company.includes(search) || ticket.includes(search);
        });
      } else {
        // todos
        this.$store.state.chats.list = this.chats
      }
    },

 
    chatsFiltered() {
    var c = this.chats;
    // buscador
    if (this.form.search.length > 0) {
      c = c.filter(chat => {
        const search = this.form.search.toLowerCase();
        const users = chat.users.filter(user => {
          const name = user.name ? user.name.toLowerCase() : '';
          const email = user.email ? user.email.toLowerCase() : '';
          return name.includes(search) || email.includes(search);
        });
        const company = chat.company && chat.company.name ? chat.company.name.toLowerCase() : '';
        const ticket = chat.ticket && chat.ticket.title ? chat.ticket.title.toLowerCase() : '';
        return users.length > 0 || company.includes(search) || ticket.includes(search);
      });
    }
    // return c;
  //   // agrupamos por id
    var groupedData = _.groupBy(c, 'id');
    if(this.$store.state.auth.rol == 'client') {
      // return  _.groupBy(c, 'users');
      const groupedItems = _.groupBy(c, (chat) => {
        // if(item.rol == 'asesor')
          return chat.users[0].id;
      });
      return groupedItems;
    }
      
    
    if(this.form.filter == 'default') {
      // deberiamos agrupar por empresa si tiene empresa, sino por nombre
      const groupedData = _.groupBy(c, chat => {
        if(this.$store.state.auth.rol == 'asesor') {
          // si tiene empresa entonces por empresa id
          if (chat.company) {
            return chat.company.id;
          } else if (chat.despacho) {
            return 'despacho';
          }
           else {
            var userOut = false
            chat.users.forEach(user => {
              if(user.id !== this.$store.state.auth.id)
                userOut = user 
            });
            
            if(userOut)
              return userOut.id
            else
              return "1"

          }
        } 
        // else {
        //   if(chat.ticket)
        //     return chat.ticket
        //   else
        //     return chat.users
        // }
        
      });
      return groupedData
      // return groupedData;
    }

    // si es por empresa lo agrupamos por id de empresa
    if(this.form.filter == 'clients') {
      groupedData = _(c)
      .filter((obj) => obj.company_id !== null)
      .groupBy('company_id')
      .value();
    }
    // tickets
    if(this.form.filter == 'tickets') {
      var t = this.chats
      groupedData = _(t).groupBy('ticket.id').value();
      return groupedData
    }

    // equipo asesores
    if(this.form.filter == 'asesores') {

      // this.key++
      var a = this.chats
      // a = a.filter(chat => chat.company == 1);
      var grouped = _.groupBy(a, chat => {
        var userOut = "c";
        chat.users.forEach(user => {
          if(user.id !== this.$store.state.auth.id && user.rol == 'asesor')
            userOut = user.id
          
          
        });
        return userOut
      })
      return grouped;
      
    }
     return groupedData;
    },
    filterType(typeIn) {
      this.form.filter = typeIn;
    },
    
    connectToSocket() {
      console.log('suscribing USER channel')
      window.Echo
          .join("User."+this.$store.state.auth.id)
          .listen('NotificationEvent', (e) => {
            if(e.type == "chat") {
              console.log()
            }

          });
    },
    // a partir de un id nos re-odena el chat y pone el primero
    // orderList(chatIn) {
    //   // Filtrar la lista para que el elemento con ID 5 esté primero
    //   const firstItem = this.chats.find(chat => chat.id == chatIn.id);
    //   if(firstItem) {
    //     firstItem.messagesPending = true;
    //     const remainingItems = this.chats.filter(chat => chat.id !== chatIn.id)
    //     this.chats = [firstItem, ...remainingItems];
    //     this.key++;
    //   } else {
    //     // recargamos listado de chats
    //     this.loadData()

    //   }
        
    // },
  }
};
</script>
