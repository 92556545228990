<template>

  <main class="mt-0 main-content main-content-bg">
    <section>
      <div class="page-header min-vh-75">
        <div class="container">
          <div class="row">
            <div class="mx-auto col-xl-4 col-lg-5 col-md-6 d-flex flex-column">
              <div class="mt-8 card card-plain">
                <div class="pb-0 card-header text-start">
                  <div v-if="this.$store.state.baseUrl == 'https://api-app-version-0.innoeasesores.com'" class="text-center">
                    <img :src="logo" height="100" class="rounded" alt="...">
                  </div>
                  <div v-else class="text-center">
                    <img :src="logo" height="100" class="rounded" alt="...">
                  </div>
                </div>
                <div class="card-body mt-2">
                  <form  class="text-start" @submit.stop.prevent="login()" on>
                    <label>Email</label>
                    <vsud-input
                      id="email"
                      type="email"
                      placeholder="Email"
                      name="email"
                      v-model="form.email"
                    />
                    <label>Contraseña</label>
                    <vsud-input
                      id="password"
                      type="password"
                      placeholder="Password"
                      name="password"
                      v-model="form.password"
                    />
                    <vsud-switch v-if="false" id="rememberMe" name="rememberMe" checked>
                      Remember me
                    </vsud-switch>
                    <div class="text-center">
                      <vsud-button
                        class="my-4 mb-2"
                        variant="gradient"
                        color="success"
                        full-width
                        >
                        <span v-if="!loading" >Iniciar Sesión</span>
                        <Cargador v-if="loading" />
                      </vsud-button>
                    </div>
                  </form>
                  <div v-if="credentialError" class="alert alert-warning mt-3" role="alert">
                    Parece que hay datos Incorrectos
                  </div>
                </div>
                 <!-- {{this.$store.state.auth}} -->

                <!-- Imagen de lado -->
                <!-- <div class="px-1 pt-0 text-center card-footer px-lg-2">
                  <p class="mx-auto mb-4 text-sm">
                    Don't have an account?
                    <router-link
                      :to="{ name: 'Sign Up' }"
                      class="text-success text-gradient font-weight-bold"
                      >Sign up</router-link
                    >
                  </p>
                </div> -->
              </div>
            </div>
            <div class="col-md-6">
              <div
                class="top-0 oblique position-absolute h-100 d-md-block d-none me-n8"
              >
                <div
                  class="bg-cover oblique-image position-absolute fixed-top ms-auto h-100 z-index-0 ms-n6"
                  :style="{
                    backgroundImage:
                      'url(' +
                      require('@/assets/img/curved-images/fondo_empresas.jpg') +
                      ')',
                  }"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
// import AppFooter from "@/examples/PageLayout/Footer.vue";
import VsudInput from "@/components/VsudInput.vue";
import VsudSwitch from "@/components/VsudSwitch.vue";
import VsudButton from "@/components/VsudButton.vue";
import Cargador from "@/components/Cargador.vue";

const body = document.getElementsByTagName("body")[0];
import { mapMutations } from "vuex";
import logo from "@/assets/img/logo.png";
import axios from 'axios';
import cookie from 'cookiejs';


export default {
  name: "SignIn",
  components: {
    // AppFooter,
    VsudInput,
    VsudSwitch,
    VsudButton,
    Cargador,
  },
  data() {
    return {
      form: {
        email:"",
        password:""
      },
      loading: false,
      credentialError: false,
      logo
    }
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add("bg-gray-100");
  },
  methods: {
    login() {
      // Api login call
      console.log('form',this.form)
      this.loading = true;
      this.credentialError = false;
      var self = this
      axios
      .post(this.$store.state.baseUrl+'/api/login',this.form)
      .then(function (response) {
         if(response.data.rc == 1) {
           self.loginSuccess(response.data.data)
         }
         if(response.data.rc !== 1) {
           self.credentialError = true
         }
      })
      .catch(() => this.credentialError = true)
        // finally
      .finally(() => this.loading = false)


    },


    // add user info to store
    loginSuccess(data) {
      // add data user
      this.$store.state.auth = data.user;
      // add token to cookie
      cookie.set("authToken",data.token,{expires: 365});
      // push the router to home
      // this.$router.push({ name: "/" }); Así no hace un refresh y no carga el usuario
      window.location.href = '/';
      

    },




    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
  },
};
</script>
