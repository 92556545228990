<template>

  <div class="">
    <h5>Pendientes: </h5>
    <div class="row mt-4">

      <div  class="col-md  mb-xl-0 mb-4">
        <router-link
        :to="{
          path:'tickets'
          }"
        >
          <mini-statistics-card
            title="Asuntos"
            :value="info.numTickets"
            :percentage="{
              value: '+505%',
              color: 'text-success',
            }"
            :icon="{
              component: 'ni ni-check-bold',
              background: iconBackground,
            }"
            direction-reverse
          />
      </router-link>
      </div>
      <div class="col-md  mb-xl-0 mb-4">
        <router-link
        :to="{
          path:'chats'
          }"
        >
        <mini-statistics-card
          title="Chats"
          :value="this.$store.state.general.pendingMessages.length"
          :percentage="{
            value: '+3%',
            color: 'text-success',
          }"
          :icon="{
            component: ' ni ni-chat-round',
            background: iconBackground,
          }"
          direction-reverse
        />
        </router-link>
      </div>
      <div  class="col-md  mb-xl-0 mb-4">
        <router-link
        :to="{
          path:'notificaciones'
          }"
        >
          <mini-statistics-card
            title="Notificaciones"
            :value="this.$store.state.pendingNotis(this.$store.state.general.notifications).length"
            :percentage="{
              value: '+3%',
              color: 'text-success',
            }"
            :icon="{
              component: ' ni ni-bell-55',
              background: iconBackground,
            }"
            direction-reverse
          />
      </router-link>
      </div>
      <div  class="col-md  mb-xl-0 mb-4">
        <router-link
        :to="{
          name:'Reuniones'
          }"
        >
        <mini-statistics-card
          title="Próximas Reuniones"
          :value="info.nextMeets"
          :percentage="{
            value: '+3%',
            color: 'text-success',
          }"
          :icon="{
            component: ' ni ni-calendar-grid-58',
            background: iconBackground,
          }"
          direction-reverse
        />
      </router-link>
      </div>
      <div class="col-md  mb-xl-0 mb-4 text-xs">
        <router-link
        :to="{
          name:'Solicitud de Formularios'
          }"
        >
          <mini-statistics-card
            title="Formularios"
            :value="info.pendingForms"
            :percentage="{
              value: '+3%',
              color: 'text-success',
            }"
            :icon="{
              component: ' ni ni-world',
              background: iconBackground,
            }"
            class="text-xs"
            direction-reverse
          />
      </router-link>
      </div>
      <!-- <div v-if="this.$store.state.auth.rol == 'asesor'" class="col-md  mb-xl-0 mb-4">
        <router-link
        :to="{
          name:'Clientes'
          }"
        >
        <mini-statistics-card
          title="Clientes"
          :value="info.clients"
          :percentage="{
            value: '-2%',
            color: 'text-danger',
          }"
          :icon="{
            component: 'ni ni-paper-diploma',
            background: iconBackground,
          }"
          direction-reverse
        />
      </router-link>
      </div> -->

    </div>
  </div>

</template>

<script>


import MiniStatisticsCard from "@/examples/Cards/MiniStatisticsCard.vue";

export default {
  name: "Tarjetas",
  props: ['info'],
  data() {
    return {
      iconBackground: "bg-gradient-success",
      loading:false
    }
  },
  created() {
    console.log(this.info,'INFO RECIBIDIDA TARJETASM')

  },
  components: {
    MiniStatisticsCard,

  }


};
</script>
