<template>
  <div class="py-4 container-fluid">

    <Cargador v-if="loading" />
    <div v-if="!this.loading && this.error" class="alert alert-warning" role="alert">
      Parece que hay algun error
    </div>
    <Tarjetas v-if="!this.loading" :info="this.info" />

    <div class="row">
      <div class="col-lg-7 mb-lg-0 mb-4">

        <!-- PARA CLIENTES -->
        <Info></Info>
        <!--  -->
        <Team v-if="this.$store.state.auth.rol == 'client'" class="mt-4" />

        <div v-if="this.$store.state.auth.rol == 'asesor'" class="card">
          <div class="card-body">
            <router-link
            :to="{
              path:'tickets'
              }"
            >
            <h5 class="font-weight-bolder">Asuntos Pendientes:</h5>
            </router-link>
            <tickets :max="3"></tickets>
            <router-link
            :to="{
              path:'tickets'
              }"

              class="text-body text-sm font-weight-bold mb-0 icon-move-right mt-auto"
            >
              Todos
              <i
                class="fas fa-arrow-right text-sm ms-1"
                aria-hidden="true"
              ></i>
            </router-link>
          </div>
        </div>
      </div>
      <!-- Noticias -->
      <News :news="this.news" />
    </div>





    
    <!-- Datos Asesor -->
    <div style="opacity: 0.3;" v-if="this.$store.state.auth.rol == 'asesor' && !this.loading" class="mt-4 row">
      <h5 class="font-weight-bolder p-2">Estadísticas/Datos <strong>(Próximamente)</strong></h5>
      <h6>Datos de prueba</h6>
      <div class="mb-4 col-lg-5 mb-lg-0">
        <div class="card z-index-2">
          <div class="p-3 card-body">
            <reports-bar-chart
              id="chart-bar"
              title="Tickets"
              description="(<strong>+23%</strong>) en el útlimo mes"
              :chart="{
                labels: [
                  'Apr',
                  'May',
                  'Jun',
                  'Jul',
                  'Aug',
                  'Sep',
                  'Oct',
                  'Nov',
                  'Dec',
                ],
                datasets: {
                  label: 'Sales',
                  data: [450, 200, 100, 220, 500, 100, 400, 230, 500],
                },
              }"
              :items="[
                {
                  icon: {
                    color: 'primary',
                    component: faUsers,
                  },
                  label: 'clientes',
                  progress: { content: '37K', percentage: 60 },
                },
                {
                  icon: { color: 'info', component: faHandPointer },
                  label: 'chats',
                  progress: { content: '2m', percentage: 90 },
                },
                {
                  icon: { color: 'warning', component: faCreditCard },
                  label: 'ventas',
                  progress: { content: '435$', percentage: 30 },
                },
                {
                  icon: { color: 'danger', component: faScrewdriverWrench },
                  label: 'Errores',
                  progress: { content: '43', percentage: 50 },
                },
              ]"
            />
          </div>
        </div>
      </div>
      <div class="col-lg-7">
        <!-- line chart -->
        <div class="card z-index-2">
          <gradient-line-chart
            id="chart-line"
            title="Tickets realizados"
            description="<i class='fa fa-arrow-up text-success'></i>
      <span class='font-weight-bold'>4% más</span> en 2021"
            :chart="{
              labels: [
                'Apr',
                'May',
                'Jun',
                'Jul',
                'Aug',
                'Sep',
                'Oct',
                'Nov',
                'Dec',
              ],
              datasets: [
                {
                  label: 'Mobile Apps',
                  data: [50, 40, 300, 220, 500, 250, 400, 230, 500],
                },
                {
                  label: 'Websites',
                  data: [30, 90, 40, 140, 290, 290, 340, 230, 400],
                },
              ],
            }"
          />
        </div>
      </div>
    </div>
    <!-- <div class="row my-4">
      <div class="col-lg-8 col-md-6 mb-md-0 mb-4">
        <projects-card />
      </div>
      <div class="col-lg-4 col-md-6">
        <timeline-list
          class="h-100"
          title="Orders overview"
          description="<i class='fa fa-arrow-up text-success' aria-hidden='true'></i>
        <span class='font-weight-bold'>24%</span> this month"
        >
          <timeline-item
            color="success"
            icon="bell-55"
            title="$2400 Design changes"
            date-time="22 DEC 7:20 PM"
          />
          <TimelineItem
            color="danger"
            icon="html5"
            title="New order #1832412"
            date-time="21 DEC 11 PM"
          />
          <TimelineItem
            color="info"
            icon="cart"
            title="Server payments for April"
            date-time="21 DEC 9:34 PM"
          />
          <TimelineItem
            color="warning"
            icon="credit-card"
            title="New card added for order #4395133"
            date-time="20 DEC 2:20 AM"
          />
          <TimelineItem
            color="primary"
            icon="key-25"
            title="Unlock packages for development"
            date-time="18 DEC 4:54 AM"
          />
          <TimelineItem
            color="info"
            icon="check-bold"
            title="Notifications unread"
            date-time="15 DEC"
          />
        </timeline-list>
      </div>
    </div> -->
  </div>
</template>
<script>
// import MiniStatisticsCard from "@/examples/Cards/MiniStatisticsCard.vue";
import ReportsBarChart from "@/examples/Charts/ReportsBarChart.vue";
import GradientLineChart from "@/examples/Charts/GradientLineChart.vue";
import News from "@/views/components/Dashboard/news.vue";
import Cargador from "@/components/Cargador.vue";
import axios  from 'axios';
import Team from "@/views/components/Comunicacion/teamAsigned.vue";
import tickets from "@/views/components/Tickets/table.vue";
import Tarjetas from "@/components/tarjetas.vue";
import Info from "@/views/components/Dashboard/infoCompany.vue";


// import TimelineList from "./components/TimelineList.vue";
// import TimelineItem from "./components/TimelineItem.vue";
// import ProjectsCard from "./components/ProjectsCard.vue";
import US from "../assets/img/icons/flags/US.png";
import DE from "../assets/img/icons/flags/DE.png";
import GB from "../assets/img/icons/flags/GB.png";
import BR from "../assets/img/icons/flags/BR.png";
import {
  faHandPointer,
  faUsers,
  faCreditCard,
  faScrewdriverWrench,
} from "@fortawesome/free-solid-svg-icons";
export default {
  name: "dashboard-default",
  data() {
    return {
      iconBackground: "bg-gradient-success",
      faCreditCard,
      faScrewdriverWrench,
      faUsers,
      faHandPointer,
      loading: true,
      error:false,
      info: null,
      news:[],
      sales: {
        us: {
          country: "United States",
          sales: 2500,
          value: "$230,900",
          bounce: "29.9%",
          flag: US,
        },
        germany: {
          country: "Germany",
          sales: "3.900",
          value: "$440,000",
          bounce: "40.22%",
          flag: DE,
        },
        britain: {
          country: "Great Britain",
          sales: "1.400",
          value: "$190,700",
          bounce: "23.44%",
          flag: GB,
        },
        brasil: {
          country: "Brasil",
          sales: "562",
          value: "$143,960",
          bounce: "32.14%",
          flag: BR,
        },
      },
    };
  },
  components: {
    // MiniStatisticsCard,
    ReportsBarChart,
    GradientLineChart,
    News,
    Cargador,
    Team,
    tickets,
    Tarjetas,
    Info

  },
  mounted() {
    // alert('das');
    this.getInfo()
  },
  methods: {
    getInfo() {
      var token = this.$store.state.token

      axios.defaults.headers.common['Authorization'] = 'Bearer '+token
      axios.defaults.headers.common['Access-Control-Allow-Origin'] = "*"

      var self = this
      axios.post(this.$store.state.baseUrl+'/api/dashboard', {
        company_id: this.$store.state.currentCompany_id
      })
        .then(response => {
          if(response.data.rc == 1) {
            this.info = response.data.data
            this.news = response.data.data.news
            this.$store.state.general.notifications = response.data.data.notifications
          } else {
            this.error = true
          }
        })
        .catch(function () {
            self.error = true
        })
        .finally(function () {
            self.loading = false
        })
    }
  }
};
</script>
