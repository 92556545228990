<template>
  <div v-if="!loading">
  

    <div v-for="asesor in this.asesores" :key="asesor.id" class="accordion-item">
      <!-- header -->
        <div class="accordion-header p-3"  data-bs-toggle="collapse" :data-bs-target="'#collapse-'+asesor.id" aria-expanded="false" aria-controls="collapseExample">
          <div class="d-flex w-100 justify-content-between">
            {{ asesor.name }}
            <bouble v-if="count = this.countPendingOfAsesor(asesor)" :num="count" class="pendingMessageBoubleChatListGeneral"></bouble>

            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle mt-auto mb-auto" viewBox="0 0 16 16">
              <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
            </svg>
          </div>
          
        </div>
        <!-- content -->
      <div class="collapse accordion-collapse p-3" :id="'collapse-'+asesor.id">
        <div class="">
          <ul class="list-group">
            <div>
              <router-link   
                
                class="list-group-item"
                v-for="chat in this.getChatsByAsesor(asesor)"  
                :key="chat.id" 
                :to="{
                  name: 'Chat',
                  params: {
                    id: chat.id
                  }
                  
                }"
               
                >
                <div class="d-flex w-100 justify-content-between">
                    <span class="m-1">
                      {{this.getNameChat(chat)}} ({{chat.id}})
                      <span v-if="chat.pending?? false" class="bouble mt-3"></span>
                    </span>

                    <bouble :num="countPendings(chat)"/>

                  <!-- <small class="text-xs">{{this.$store.state.dateFormat(chat.updated_at)}}</small> -->
                  <!-- <span v-if="hasPending(chat,asesor)" class="pendingMessageBoubleChatList"></span> -->

                </div>
                <div v-if="chat.last_message[0]" class="d-flex w-100 justify-content-between">
                  <!-- <small>{{chat.last_message[0].message}}</small> -->
                  <small class="text-xs">{{this.$store.state.dateFormat(chat.last_message[0].created_at)}}</small>
                </div>

              </router-link >
            </div>

            
            
          </ul>
        </div>
      </div>
      <hr>
    </div>
  </div>
</template>

<script>
import _  from 'lodash';
import bouble from "@/components/bouble.vue"



export default {
  name: "all",
  data() {
    return {
      groupedData: [],
      counter: 1,
      asesores: [],
      chats: [],
      loading:true
    };
  },
  computed: {
    incrementedCounter() {
      return () => this.counter++;
    }
  },
  components:{
    bouble
  },
  created() {
    this.chats = _.filter( this.$store.state.chats.list, (chat) => {
        return !chat.despacho;
      });
   
    
    // primero recogemos los asesores
    this.getAsesresOfChats();
  },
  methods: {

      // contar a partir de un chat
      countPendings(chat) {
      const f = _.filter(this.$store.state.general.pendingMessages, { chat_id: chat.id });  
      return _.size(f);  

    },
    //
    countPendingOfAsesor(asesor) {
      const chatIDs = _.map(this.getChatsByAsesor(asesor), 'id');
      const mensajesConMismoChatID = _.filter(this.$store.state.general.pendingMessages, (mensaje) => _.includes(chatIDs, mensaje.chat_id));
      console.log(mensajesConMismoChatID);
      return _.size(mensajesConMismoChatID);


    },
    getAsesresOfChats() {
      
      // recrremos los chats  y añadimos los usuarios
     this.chats.forEach(chat => {
        chat.users.forEach(user => {
          if(!this.asesores.some(asesor => asesor.id === user.id)) {
            if(user.rol == 'asesor' && user.id !== this.$store.state.auth.id)
              this.asesores.push(user);
          }

        });

      });
      this.loading = false
    },
    // from the asesor we get the chat
    getChatsByAsesor(asesor){
      var chats = [];
      this.chats.forEach(chat => {
        chat.users.forEach(user => {
          if(asesor.id == user.id) {
            if(!chats.some(chatIn => chatIn.id === chat.id)) {
              chats.push(chat);
            }
          }
          

        });

      });
      return this.chatsFirstGeneral(chats);
    },
    getCounter() {
      return this.counter++;
      
    },
    quitPending(chat) {
      if (chat.last_message && chat.last_message.length > 0) {
        var n = JSON.parse(chat.last_message[0].readed_by);
        n.push(this.$store.state.auth.id)
        chat.last_message[0].readed_by = JSON.stringify(n);
        this.$store.state.general.pendingMessages--;
        if(this.$store.state.general.pendingMessages<0)
          this.$store.state.general.pendingMessages=0
      }
    },


    getNameChat(chat){
      // const rol = this.$store.state.auth.rol
      // nombre ticket
      if(chat.ticket)
        return "Asunto - "+chat.ticket.title
      if(chat.company)
        return chat.company.name
      // si es asesor y hay empresa empresa
      // if(chat.company && rol == 'asesor')
      //   return chat.company.name
      return "General"
    },
    // el nombre en header
    getGeneralName(groupedChats) {
      // recogemos el primer chat
      const firstChat = groupedChats[0];
      // rol del user 
      const rol = this.$store.state.auth.rol
      // si tiene empresa y es asesor se verá el nombre de la empresa
      if(rol == 'asesor' && firstChat.company)
        return firstChat.company.name
      // si el chat tiene ticket sin empresa
      if(firstChat.ticket && firstChat.company == null)
        return "Interno - Asesores"
      // si es despacho se mostrará el nombre despacho
      if(rol == 'asesor' && firstChat.despacho)
        return "Despacho"
      // finalmente, independientemente del rol. Se mostrará el nombre 
      var name = "Desconocido"
      firstChat.users.forEach(user => {
        if(user.id !== this.$store.state.auth.id)
          name = user.name
      });
      return name;
      
    },
    hasPending(chat, asesor) {
      if(asesor == undefined)
        return false
      
      if(chat.last_message == null)
        return false;
      if(chat.last_message.length == 0)
        return false;
      
      var n = JSON.parse(chat.last_message[0].readed_by);
      console.log('pending')
      console.log(n,asesor.id)
      return n.includes(asesor.id);
    },
    countPendingsChats(groupedChats,asesor) {
      console.log('cuntingPendings',groupedChats)
      var count = 0;
      groupedChats.forEach(chat => {
        if(this.hasPending(chat,asesor))
          count++;
      });
      return count;
    },
    // group chats
    groupedChats() {
      // this.groupedData = _.groupBy(this.$store.state.chats.list, 'id');
      const chats = this.$store.state.chats.list;
      const groupedData = _.groupBy(chats, chat => {
        if(this.$store.state.auth.rol == 'asesor') {
          // si tiene ticket de empresa se agrupa por empresa
          if(chat.ticket && chat.company)
            return JSON.stringify(chat.company)
          // si tiene ticket sin empresa lo agrupamos por Equipo
          if(chat.ticket && chat.company == null)
            return "Interno"
          // Si Es empresa se agrupa por empresa
          if (chat.company) {
            return JSON.stringify(chat.company)
          } 
          if (chat.despacho) {
            return 'despacho';
          }
           else {
            var userOut = false
            chat.users.forEach(user => {
              if(user.id !== this.$store.state.auth.id)
                userOut = user 
            });
            
            if(userOut)
              return userOut.id
            else
              return "1"

          }
        } 
        //  si es cliente
        else {
          // si tiene ticket por el asesor que lo lleva
          if(chat.ticket) {
            var asesorOut = "Desconocido"
            chat.ticket.asesores.forEach(asesor => {
              if(asesor.pivot.admin)
              asesorOut = asesor 
            });
            return asesorOut.id
          }
            
          // sino por sus usuarios
          else {
            var idOut
            chat.users.forEach(user => {
              if(user.id !== this.$store.state.auth.id){
                idOut = user.id
              }
            });
            return idOut
          }
            
          
        }
      }); 
      this.groupedData = groupedData;
      return groupedData;
    },
    // ordenamos con tal de que los chats agrupados quede el "chat general" primero
    chatsFirstGeneral(grouped) {
      console.log('grouped',grouped)
      const sortedData = _.orderBy(grouped, ['ticket'], ['desc']);

      return sortedData
      
        
      
    }
  }

  
  
};
</script>
