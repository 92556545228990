<template>
  <div  class="currentChat">
    <Cargador v-if="loadingAll"/>
    <div v-if="!loadingAll" class="chat">
        <div  class="chat-header clearfix">
          <headerChat :data="data"/>
          
        </div>
        <div  class="chat-history" ref="chat">
            <ul class="m-b-0">
                <message v-for="message in data.messages"  :key="message" :dataIn="data" :message="message" ></message>
            </ul>
        </div>
        <div v-if="this.error" class="alert alert-danger mt-3">
                  Parece que ha habido un error
            </div>
        <div  class="chat-message clearfix" :class="{'public-message' : this.form.public && this.$store.state.auth.rol !== 'client' }">
           
            
            
                <VueEditor :placeholder="'Escribir mensaje'" @keydown="handleKeyDown" :editorOptions="editorSettings" :key="this.keyEditorMessage" id="editorMEssage"  v-model="this.form.message"/>
                <!-- <input type="text" v-on:keyup.enter="sendMessage()" v-model="this.form.message" class="form-control"  placeholder="Escribir mensaje"> -->
                <div class="p-3">
                  <soft-switch
                    v-if="this.$store.state.auth.rol !== 'client'"
                    id="flexSwitchCheckDefault"
                    name="email"
                    class="ps-0 ms-auto"
                    label-class="mb-0 text-body ms-3  w-80"
                    :value="form.public"
                    @valor="form.public=$event"
                    >Visible a Cliente
                  </soft-switch>
                  <button @click="this.sendMessage()" class="btn btn-primary ">

                    <Cargador :width="24" v-if="this.loading"/>
                    <div class="d-flex" v-else>
                      Enviar 
                      <svg  xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-send ms-2" viewBox="0 0 16 16">
                        <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z"/>
                      </svg>
                    </div>
                    
                  </button>
                </div>
                
            
        </div>
    </div>
  </div>


</template>

<script>
// import "quill-emoji/dist/quill-emoji.css";

import { VueEditor, Quill } from 'vue3-editor';
import Emoji from 'quill-emoji/dist/quill-emoji';
Quill.register({
'formats/emoji': Emoji.EmojiBlot,
'modules/short_name_emoji': Emoji.ShortNameEmoji,
'modules/toolbar_emoji': Emoji.ToolbarEmoji,
'modules/textarea_emoji': Emoji.TextAreaEmoji}, true);
import headerChat from "./headerChat.vue";
import message from "@/views/components/Chats/message.vue";

import Cargador from "@/components/Cargador.vue";
import axios  from 'axios';
import SoftSwitch from "@/components/SoftSwitch.vue";





export default {
  name: "currentChat",
  // emits: ['new-message'],

  data() {
    return {
      auth:this.$store.state.auth,
      loading:false,
      loadingAll:true,
      data: null,
      error:false,
      userIds: [this.$store.state.auth.id],
      keyEditorMessage: 0,
      form: {
        message:"",
        chat_id: this.$route.params.id,
        public: this.$store.state.auth.rol == 'client'

      },
      editorSettings: {
              modules: {
              
              toolbar: {
                  container: [
                      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                      [{'size': ['small', false, 'large']}],
                      ['bold', 'italic', 'underline', 'strike'],
                      ['blockquote', 'code-block'],
                      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                      [{ 'script': 'sub' }, { 'script': 'super' }],
                      [{ 'indent': '-1' }, { 'indent': '+1' }],
                      [{ 'direction': 'rtl' }],
                      [{ 'color': [] }, { 'background': [] }],
                      [{ 'font': [] }],
                      [{ 'align': [] }],
                      ['clean'],
                      ['link', 'image', 'video'],
                      ['emoji'],
                  ],
                  handlers: {
                    'emoji': function () {
                      const range = this.quill.getSelection();
                      this.quill.insertText(range.index, '🙂');
                      this.quill.setSelection(range.index + 1);
                    }
                },
              },
              toolbar_emoji: true,
              short_name_emoji: true,
              textarea_emoji:true,
          },
      },

    };
  },
  components: {
    Cargador,
    headerChat,
    VueEditor,
    // readedBy,
    message,
    SoftSwitch
  },
  unmounted() {
    // desuscripciíon del canal
    this.unSuscribeChannel();
    console.log('Se ha cambiado de ruta en Componente 1')
    // next()
  },
  mounted() {

    this.getData();
    
    
  },
  methods: {
    handleKeyDown(event) {
      console.log(event)
      if (event.key === 'Enter') {
        if (event.ctrlKey || event.metaKey) {
          this.sendMessage();
        } 
      }
    },
    handleEnter() {
      if (this.lastEnterTime && Date.now() - this.lastEnterTime < 500) {
        // Doble clic en Enter sin escribir nada
        this.doubleEnterAction();
      } else {
        this.lastEnterTime = Date.now();
      }
    },
    handleControlEnter() {
      this.controlEnterAction();
    },
    doubleEnterAction() {
      // Acción para doble clic en Enter sin escribir nada
      // Puedes colocar tu lógica aquí
    },
    controlEnterAction() {
      // Acción para clic en Control+Enter
      // Puedes colocar tu lógica aquí
    },
    insertEmoji(emoji) {
    this.form.message += emoji;
  },
    markMessageAsRead(){

    },
    getData(){
      var token = this.$store.state.token
      var self = this;
      axios.defaults.headers.common['Authorization'] = 'Bearer '+token
      axios.post(this.$store.state.baseUrl+'/api/chat/'+this.$route.params.id, {
        company_id: this.$store.state.currentCompany_id
      })
        .then(response => {
          if(response.data.rc == 1) {
            this.data = response.data.data
            this.data.messages = this.data.messages.reverse();
            this.suscribeChannel()
            this.scrollChat()
            this.fetchUserIds()
          }
        })
        .catch(function () {
            self.error = true
        })
        .finally(function () {
            self.loadingAll = false
        })
    },
    findAsesorById(id) {
      var asesorOut;
      this.$store.state.general.asesores.forEach((asesor) => {
        if(asesor.id == id)
          asesorOut = asesor
      });
      return asesorOut

    },
    
    sendMessage() {
      if(this.form.message == '')
        return;
      
      let message = this.form.message
      this.form.message = ""
      this.keyEditorMessage++;
      // this.data.messages.push({
      //   message:message,
      //   user_id: this.$store.state.auth.id,
      //   created_at: new Date(),
      //   user: this.$store.state.auth,
      //   from_system: false,
      //   readed_by: JSON.stringify([this.$store.state.auth.id])
      // })
      this.scrollChat();
      
      this.loading = true
      var self = this;
      var token = this.$store.state.token

      axios.defaults.headers.common['Authorization'] = 'Bearer '+token
      axios.post(this.$store.state.baseUrl+'/api/send', {
        message: message,
        chat_id: self.$route.params.id,
        users_ids: self.userIds,
        public: self.form.public
      })

        .then(response => {
          if(response.data.rc == 1) {
            //oel
            this.error = false
            this.scrollChat()
            console.log('users_ids',this.userIds)

          }
        })
        .catch(function () {
            self.error = true
        })
        .finally(function () {
            self.loading = false
        })

    },
    reciveMessage(info) {
      this.data.messages.push({
        message:info.message,
        user_id:0,
        created_at: new Date(),
        user: info.user,
        ticket_id: info.ticket_id,
        from_system: info.from_system,
        public: info.public
      })
    },
    isFromother(message) {
      if(message.user)
        return message.user_id !== this.$store.state.auth.id;
      else
        return false
    },
  
    // suscribe to socket channel
    suscribeChannel() {
      console.log('suscribing chat channel')
      window.Echo
          .join("Chat."+this.data.id)
          .listen('MessageEvent', (e) => {
            console.log("MESSAGEEVENT",e)
            // if(e.user_id !== this.$store.state.auth.id) {
              this.data.messages.push({
                id: e.id,
                message:e.message,
                user_id:e.user_id,
                created_at: e.created_at,
                user: e.user,
                from_system: e.from_system,
                ticket_id: e.ticket_id,
                document_id: e.document_id,
                readed_by: e.readed_by,
                public: e.public


              })
              this.scrollChat()
              // this.$emit('new-message', e);

            // }

          })
          .listen('ReadedEvent', (e) => {
            for (let index = 0; index < this.data.messages.length; index++) {
              const element = this.data.messages[index];
              if(element.id == e.id) {
                this.data.messages[index] = {
                  message: e.message,
                  user_id:e.user_id,
                  created_at: e.created_at,
                  user: e.user,
                  from_system: e.from_system,
                  ticket_id: e.ticket_id,
                  document_id: e.document_id,
                  readed_by: e.readed_by,
                  public: e.public

                }
              }
              
            }
           
            
            console.log("ReadedEvent",e)


          });
          
        
    },
    unSuscribeChannel() {
      console.log('suscribing chat channel')
      if(this.data)
        window.Echo.leave("Chat."+this.data.id)
        
    },
    scrollChat() {
      try {
        window.setTimeout(() => {
          var messagewindow = this.$refs.chat;
          if (messagewindow)
            messagewindow.scrollTop  = messagewindow.scrollHeight+300;
        },0);
      } catch (error) {
        console.error(error)
      }
        
    },
    fetchUserIds() {
      window.Echo.join("Chat."+this.data.id)
        .here(users => {
          console.log('here',users)
          this.userIds = users.map(user => user.id);
        })
        .joining(user => {
          console.log('joining',user)
          this.userIds.push(user.id);
        })
        .leaving(user => {
          const index = this.userIds.indexOf(user.id);
          if (index > -1) {
            this.userIds.splice(index, 1);
          }
        });
    }
  }
};
</script>
<style>
.ql-image, .ql-video{
  display: none !important;
}
</style>